<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />     
        </div>
        <div v-else>
            <tabelVersionControl :version="version" @editForm="editForm"/>
        </div>
        <ModalVersionControlCreate :templates="templates" :services="services" :platform="platform" @refresh="refresh"/>
        <ModalVersionControlEdit :id="id" :templates="templates" :services="services" :platform="platform" @refresh="refresh"/>  
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>

<script>
import ModalVersionControlCreate from '@/views/component/Modal/ModalVersionControl/ModalVersionControlCreate.vue';
import ModalVersionControlEdit from '@/views/component/Modal/ModalVersionControl/ModalVersionControlEdit.vue';
import tabelVersionControl from '@/views/component/Table/tableVersionControl.vue'
export default {
    components: {
        tabelVersionControl,
        ModalVersionControlCreate,
        ModalVersionControlEdit
    },
    data() {
        return {
            showPreloader: false,
            version: [],
            templates: [],
            services: [],
            platform: [],
            id: null,
            fields: [
                // { key: 'platform', label: 'Платформа'},
                // { key: 'min_version', label: 'Минимальная версия' }
            ],
        }
    },
    mounted() {
        this.openFilter()
        this.getTemplates()
        this.getVersions()
    },
    methods: {
        openFilter() {
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, { params: param }).then(res => {
                        this.version = res.data
                        this.showPreloader = false
                        this.$store.commit('pageData/setdataCount', this.version.length)
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        sendToParent(tableData){
            this.version = tableData
        },
        refresh() {
            let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            this.showPreloader = true
            this.$http.get(`${this.$route.name}`, {params: params}).then(res => {
                this.version = res.data
                this.showPreloader = false
                this.$store.commit('pageData/setdataCount', this.version.length)
                this.$store.commit('REFRESH_DATA', false)
            })
        },
        getTemplates() {
            this.$http.get('message-templates').then(res => {
                this.templates = res.data
            })
        },
        getVersions() {
            this.$http.get('versions').then((res) => {
                this.services = res.data.services
                this.platform = res.data.platform
            })
        },
        editForm(id) {
            this.id = id
            setTimeout(() => {this.$bvModal.show('editVersionControl')}, 0);
        },
    },
    computed: {
        fetchingNewData() {
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData() {
            this.refresh()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>