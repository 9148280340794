<template>
    <div class="d-flex justify-content-center">
        <performer-map-status :performer="performer" :text="text"/>
        <map-performer-driver :performer="performer"/>
    </div>
</template>

<script>
import performerMapStatus from '@/views/default/carStateCurrent/performerMapStatus.vue'
import mapPerformerDriver from '@/views/default/carStateCurrent/mapPerformerDriver.vue'
export default {
    components: {
        mapPerformerDriver,
        performerMapStatus,
    },
data() {
    return {
        performer: this.$route.query.filter,
        text: this.$route.query.text
    }
},
}
</script>