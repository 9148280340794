import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import caller from './caller'
import draggableTab from './draggableTab'
import excel from './excel'
import pageData from './pageData'
import refresh from './refresh'
import filterPlus from './filterPlus'
import updates from './autopUpdates'
import map from './map'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    caller,
    verticalMenu,
    draggableTab,
    excel,
    pageData,
    refresh,
    filterPlus,
    updates,
    map
  },
  strict: process.env.DEV,
})
