<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table
                        striped
                        small
                        id="myTables"
                        bordered
                        selectable
                        selected-variant="primary"
                        select-mode="single"
                        responsive="sm"
                        head-row-variant="secondary"
                        :items="templates"
                        :fields="fields"
                        @row-clicked="clicked"
                        @row-dblclicked="editForm"
                        @row-contextmenu="handleContextMenuEvent"
                    >
                        <template #cell(checkbox)="row">
                            <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
                            </b-form-checkbox>
                        </template>
                        <template #cell(created_at)="data">
                            {{ moment(data.item.created_at).format('DD.MM.YYYY HH:mm') }}
                        </template>   
                        <template #cell(updated_at)="data">
                            {{ moment(data.item.updated_at).format('DD.MM.YYYY HH:mm') }}
                        </template>
                    </b-table>
                    <vue-context ref="menu">
                        <li>
                             <b-link
                             class="d-flex align-items-center"
                             @click="deleteTemplate"
                             >
                                <feather-icon
                                 icon="DeleteIcon"
                                />
                            <span class="ml-75">Удалить</span>
                            </b-link>
                        </li>
                    </vue-context>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from '@/utils/TableFunctions/resizeable';
import { cellSelect } from '@/utils/TableFunctions/cellSelect';
import moment from 'moment';
import VueContext from 'vue-context'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        VueContext,
        ToastificationContent
    },
    props: ['templates'],
    data() {
        return {
            fields: [
                { key: 'checkbox', label: '',},
                { key: 'id', label: 'ID', sortable: true},
                { key: 'title', label: 'Заголовка', sortable: true},
                { key: 'description', label: 'Описание', sortable: true},
                { key: 'created_at', label: 'Создан', sortable: true},
                { key: 'updated_at', label: 'Обновлен', sortable: true},
            ],
            id: null,
        }
    },
    mounted() {
        resizeable();
    },
    methods: {
        moment,
        clicked(item, index, event){ 
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                    return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                    return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;

            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
        editForm(item) {
            this.$emit('editForm', item.id)
        },
        handleContextMenuEvent(item, index, event) {
            event.preventDefault();
            this.$refs.menu.open(event);
            this.id = item.id;
        },
        deleteTemplate() {
            this.$http.delete(`message-templates/${this.id}`)
            .then((res) => {
                this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Шаблон удален!',
						icon: 'XIcon',
						variant: 'success',
						text: res.data.message,
					},
				});
                this.$emit('refresh')
            })
            .catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                });
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-context.scss';
.my-card {
        padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
    .my-card{
        overflow: auto; 
        height: 530px;
        }
}
table{
        outline: none; 
        table-layout: fixed; 
        -webkit-user-select: none;
        overflow: hidden;
        position: relative;
}
tr, td, th{
        padding: 0;
}
td, th{ 
        width: 100px; 
        white-space: nowrap; 
        overflow: hidden;  
        text-align: left; 
        box-sizing: border-box; 
        min-width: 60px;
        }    
</style>