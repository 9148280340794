export const createRoute = (data, e, map) => {
    map.addSource(`route-${e}`, {
        type: 'geojson',
        data: {
            'type': 'Feature',
            'geometry': {
                'type': 'LineString',
                'coordinates': data
            },
        },
    });
    map.addLayer({
        'id': `route-${e}`,
        'type': 'line',
        'source': `route-${e}`,
        'layout': {
            'line-join': 'round',
            'line-cap': 'round',
        },
        'paint': {
            'line-color': '#FFFFFF ',
            'line-width': 13,
        },
    });
    map.addLayer({
        'id': `route2-${e}`,
        'type': 'line',
        'source': `route-${e}`,
        'layout': {
            'line-join': 'round',
            'line-cap': 'round',
        },
        'paint': {
            'line-color': '#0B5FDE',
            'line-width': 7,
        },
    })
}