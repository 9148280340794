<template>
    <b-row>
        <b-col cols="2" >
            <p>{{ field.label }}</p>
        </b-col>
        <b-col class="mb-1">
            <v-select
                label="slag"
                :options="slegAll"
                v-model="sleg"
                :reduce="option => option.slag"
                @input="updateModel($event, 'slag')"
            />
        </b-col>
    </b-row>
</template>

<script>
import vSelect from 'vue-select'
export default {
    components: {
        vSelect
    },
    props: ['value', 'field'],
    name: 'typeQr',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            slegAll: [],
            sleg: localStorage.getItem("sleg") ? localStorage.getItem("sleg") : null,
        }
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'slag' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'slag';
            this.updateModel(value, name);
        }
        this.getSlegList()
    },
    methods: {
        getSlegList() {
            this.$http.get('slag-list')
            .then((res) => {
                this.slegAll = res.data.slags
            })
        },
        updateModel(value, name) {
            localStorage.removeItem('sleg')
            localStorage.setItem('sleg', value)
            this.$emit('change', { val: value, field: name })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>