<template>
<div>
     <b-modal
        ref="handle_order"
        id="handle_order"
        title="Обработка заказа"
        size="lg"
        no-enforce-focus
        :no-close-on-backdrop = "true"
        @show="getData"
        @close="onlyForReading = true"
        @shown="detectStartTime, $store.commit('caller/UPDATE_IS_ORDER_MODAL_OPENED_STATE', true)"
        @hidden="detectEndTime, $store.commit('caller/UPDATE_IS_ORDER_MODAL_OPENED_STATE', false)"
        content-class="order-content"
        >
            <b-row>
                <b-col cols="3">
                    <label for="">Подразделение</label>
                    <v-select
                    @input="calcPrice(), getTariffs()"
                    :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false"
                    v-model="editOrder.division_id"
                    :options="orderData.divisions"
                    :reduce="option => option.id"
                    placeholder="Подразделение"
                    label="name"
                    class="select-size-sm"
                    />
                    <!-- <select @input="getTariffs($event.target.value), calcPrice()" :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" v-model="editOrder.division_id" class="form-control form-control-sm">
                        <option  v-for="division in orderData.divisions" :key="division.id" :value="division.id">{{division.name}}</option>
                    </select> -->
                </b-col>
                <b-col cols="2" class="mt-1 p-0">
                   <div class="custom-control custom-checkbox">
                        <input :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" @change="notIssued" v-model="selected" value="A" type="radio" class="custom-control-input" name="vv" id="customCheck1" />
                        <label class="custom-control-label" for="customCheck1">Автоназначение</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" @change="notIssued" v-model="selected" value="B"  type="radio" name="vv" class="custom-control-input" id="customCheck2" />
                        <label class="custom-control-label" for="customCheck2">Не оформлен</label>
                    </div>
                </b-col>
                <b-col cols="3" class="mt-1">
                    <b-button
                        variant="outline-primary"
                        class="btn-icon mr-1"
                        v-b-tooltip.hover.bottom="'Служебная записка'"
                        v-b-modal.memosCreateModal
                        v-hotkey="{ 'alt+C': openMemosForm}"
                    >
                        <feather-icon icon="FileTextIcon" />
                    </b-button>
                    <b-button
                        variant="outline-primary"
                        class="btn-icon"
                        v-b-modal.driver_questionnaire
                        v-b-tooltip.hover.bottom="'Анкета водителя'"
                    >
                        <feather-icon icon="TruckIcon" />
                    </b-button>
                    <b-dropdown
                        id="dropdown-1"
                        variant="link"
                        size="sm"
                    >
                        <template #button-content>
                            <b-button
                                variant="outline-primary"
                                class="btn-icon mr-1"
                                v-b-tooltip.hover.bottom="'Звонить'"
                            >
                                <feather-icon icon="PhoneIcon" />
                            </b-button>
                        </template>
                        <b-dropdown-item :disabled="!hasPerformer" @click="callAndConnect()">Соединить</b-dropdown-item>
                        <b-dropdown-item :disabled="!hasPerformer" @click="callDriver()">Водителю</b-dropdown-item>
                        <b-dropdown-item @click="callClient()">Клиенту</b-dropdown-item>
                        <b-dropdown-item @click="callClientToDopPhone()">Клиенту на доп. номер</b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
            <b-row class="mt-1 mb-1/2">
                <b-col cols="7">
                    <div class="input-group">
                        <b-form-input v-mask="'############'" :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" size="sm" type="text" v-model="editOrder.phone" placeholder="Номер телефона" />
                        <b-form-input v-mask="'############'" :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" size="sm" type="text" v-model="editOrder.dop_phone" placeholder="Доп.номер" />
                        <b-form-input :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" size="sm" placeholder="Никнейм" />                                   
                    </div>
                </b-col>
                <b-col cols="5" class="d-flex justify-content-end">
                    <label class="d-flex align-items-center">
                        <input
                        :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false"
                        type="checkbox" name="" class="cursor-pointer mr-1/2"
                        @input="Intime" v-model="editOrder.for_time">
                        <span class="mr-1/2" style="line-height: 1;">
                            На время
                        </span>
                        <date-picker
                        v-model="editOrder.date_time"
                        :disabled="!editOrder.for_time"
                        v-mask="'####-##-## ##:##'"
                        type="datetime"
                        :disabled-date="disabledDates"
                        :disabled-time="disabledTimes"
                        format="YYYY-MM-DD HH:mm"
                        :value-type="'format'"
                        placeholder="YYYY-MM-DD HH:mm"></date-picker>
                    </label>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="7" class="mb-1/2">
                    <div class="address mb-1/2">
                        <h4>Адрес подачи</h4>
                        <b-row>
                            <b-col cols="12" class="mb-1/2">
                                <search-address-v-select
                                :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1"
                                @input="editOrder.from_address = $event, calcPrice()"
                                :value="editOrder.from_address"
                                :defaultAddresses="defaultAddresses"></search-address-v-select>
                            </b-col>
                            <b-col cols="12" class="mb-1/2">
                                <label for=""> Инфо о встрече</label>
                                <b-input :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false"
                                    size="sm" autocomplete="off" v-model="editOrder.meeting_info" placeholder="" />
                            </b-col>
                        </b-row>
                        <h4>Адрес маршрута</h4>
                        <b-row>
                            <b-col cols="12">
                                <draggable>
                                    <b-col
                                    v-for="(to_address, id) in editOrder.to_addresses"
                                    :key="id"
                                    class="d-flex mb-1/2"
                                    cols="12">
                                        <b-button
                                            :disabled="editOrder.status_id == 10 || editOrder.status_id == 9 ? true : false"
                                            size="sm" variant="outline-primary" class="btn-icon mr-1/2" v-if="id === 0"
                                            @click="routePush"
                                            v-hotkey="{ 'alt+insert': routePush }"
                                            >
                                            <feather-icon icon="PlusIcon" />
                                        </b-button>
                                        <b-button
                                            :disabled="editOrder.status_id == 10 || editOrder.status_id == 9 ? true : false"
                                            size="sm" variant="outline-primary" class="btn-icon mr-1/2" v-else
                                            @click="routeMinus(id)">
                                            <feather-icon icon="MinusIcon" />
                                        </b-button>
                                        <search-address-v-select
                                        class="w-100"
                                        :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1"
                                        @input="editOrder.to_addresses[id] = $event, calcPrice()"
                                        :value="editOrder.to_addresses[id]"
                                        :defaultAddresses="defaultAddresses"></search-address-v-select>
                                    </b-col>
                                </draggable>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="handle-container">
                        <b-tabs
                            vertical
                            nav-wrapper-class="nav-vertical"
                        >
                            <b-tab :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" @click="notChangeStatus" active title="Не менять статус">
                            </b-tab>
                            <b-tab :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" @click="status" title="Поменять статус">
                                <p class="mt-1">Статус заказа:</p>
                                <v-select
                                v-model="orderStatus"
                                :options="statuses"
                                class="select-size-md"
                                label="name"
                                :reduce="option => option.id"
                                >
                                </v-select>
                                <label v-if="orderStatus == 10" class="mt-1" for="">Причина отмены</label>
                                <v-select
                                    v-if="orderStatus == 10"
                                    v-model="editOrder.reason_cancel_order"
                                    :reduce="option => option.id"
                                    label="name"
                                    :options="reasons"
                                />
                                <div class="mt-1" v-if="editOrder.reason_cancel_order == 9">
                                    <b-form-textarea v-model="reason_cancel_order"/>
                                </div>
                            </b-tab>
                            <b-tab v-if="positionId !== 1 && editOrder.status_id == 1" :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" @click="asignment" title="Назначить водителя">
                                <p class="mt-1">Список водителей:</p>
                                <div>
                                    <label class="mx-1 my-1">
                                         <input type="radio" name="searchOption" v-model="searchByDistance" checked value="login"> По логину
                                    </label>
                                    <label class="mx-1 my-1">
                                        <input type="radio" name="searchOption" v-model="searchByDistance" value="distance"> По расстоянию
                                    </label>
                                </div>
                                <v-select
                                    v-model="performer_id"
                                    :options="drivers"
                                    class="select-size-md"
                                    :label="searchByDistance"
                                    :reduce="driver => driver.id"
                                >
                                    <template slot="option" slot-scope="option">
                                        <div>
                                         {{option.first_name+' '+option.last_name+'('+option.distance+')' + ' - ' + option.login}}
                                    </div>
                                </template>
                                <template #selected-option="option">
                                    <div style="display: flex; align-items: baseline">
                                        <div>
                                            {{option.first_name+' '+option.last_name+'('+option.distance+'км)'}}
                                        </div>
                                    </div>
                                </template>
                                </v-select>
                            </b-tab>
                            <b-tab :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" @click="rollback" title="Откат Водителя">
                                <p>Водитель: {{editOrder.performer!=null ? editOrder.performer.first_name+' '+editOrder.performer.last_name : ""}}</p>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-col>
                <b-col cols="5" class="mb-1/2">
                    <div class="info-container">
                        <h4>Тип заказа</h4>
                        <b-row>
                            <b-col class="d-flex justify-content-between mb-1/2" cols="12">
                                <b-form-radio
                                @change="calcPrice()"
                                :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false"
                                name="some-radios"
                                v-for="order_type in orderData.order_types"
                                :key="order_type.id"
                                :value="order_type.id "
                                v-model="editOrder.order_type_id"
                                >
                                    {{order_type.name}}
                                </b-form-radio>
                            </b-col>
                            <b-col cols="12" class="mb-1/2">
                                <label for="">Тариф</label>
                                <select @change="changeTariff" :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false" v-model="editOrder.tariff_id " class="form-control form-control-sm">
                                    <option v-for="tariff in tariffsList" :key="tariff.id" :value="tariff.id">{{tariff.name}}</option>
                                </select>
                            </b-col>
                            <b-col cols="12">
                                <customSelectAllowance @GetAllowances="GetAllowances" :editOrder="editOrder.allowances" :options="allowances"/>
                            </b-col>
                            <b-col cols="12">
                                <b-row>
                                <b-col >
                                    <label for="priceInCity">Город:</label>
                                    <b-form-input size="sm" id="priceInCity" v-model="newOrderData.distance_in_city" disabled ></b-form-input>
                                </b-col>
                                <b-col class="ml" >
                                    <label  for="priceInterCity">Межгород: </label>
                                    <b-form-input size="sm" id="priceInterCity" v-model="newOrderData.distance_inter_city" disabled> </b-form-input>
                                </b-col>
                                <b-col class="ml">
                                    <label for="distanceInCity">Холостой</label>
                                    <b-form-input size="sm" id="distanceInCity" v-model="newOrderData.free_km" disabled>
                                    
                                    </b-form-input>
                                </b-col>
                                </b-row>
                                <b-row class="mt-2 mb-1">
                                    <b-col>
                                        <input type="checkbox" v-model="checked" checked> Безнал
                                    </b-col>
                                    <b-col>
                                        <input type="text" :disabled="checked" v-model="editOrder.bonus_price">
                                    </b-col>
                                </b-row>
                                <div class="bonus" v-if="client_login">
                                    <p>{{ client_login }}</p>
                                    <p>{{ editOrder.phone }}</p>
                                    <p>{{ client_bonus_balance }}</p>
                                </div>
                                <h4>
                                    Надбавки:
                                    <span class="text-success" v-for="allowance in editOrder.allowances" :key="allowance.name">
                                        <span v-if="allowance.type == 'custom_type' || allowance.type == 'custom_not_add_to_price' || allowance.type == 'custom_type_multiply' || allowance.type == 'minute'">
                                            {{ allowance.name + '(' + allowance.value + '), ' }}
                                        </span>
                                        <span v-else>
                                            {{ allowance.name + ', ' }}
                                        </span>
                                    </span>
                                </h4>
                                <h4>Цена: <b class="text-danger">{{editOrder.price}}</b> смн.</h4>
                                <b-row>
                                    <b-col>
                                        <h4>Итого: <b class="text-danger">{{ editOrder.price }}</b> смн.</h4>
                                    </b-col>
                                    <b-col>
                                        <h4 style="text-align: right;"><b class="text-danger">{{ amount_cash }}</b> смн.</h4>
                                    </b-col>
                                </b-row>
                                <h4>Растояние: <b class="text-primary">{{editOrder.distance}}</b> км.</h4>
                                 <b-button variant="outline-primary" @click="ableDisableInfoModal">Подробности</b-button>
                                <div class="info-modal__interior" v-if="info_modal == true">
                                 <b-row class="mb-1">
                                       <b-col class="info-modal__tariff" cols="2">Тариф</b-col>
                                       <b-col>
                                         <select  @change="changeTariff" v-model="newOrder.tariff_id"
                                               class="form-control form-control-sm">
                                                  <option v-for="tariff in tariffsList" :key="tariff.id" :value="tariff.id">{{ tariff.name }}
                                                   </option>
                                             </select>
                                       </b-col>
                                     </b-row> 
                                     <b-row class="info-modal__title">
                                       Городской
                                     </b-row>
                                     <div class="info-modal__text">             
                                        Поездки внутри города от<span > {{tariff_info.min_price}} смн.</span>
                                      <div>В стоимость поездки входят <span>3</span> км дороги, после <span>{{tariff_info.price_km_city}} смн/км</span>.</div>
                                     </div>
                                <b-row class="info-modal__title">
                                     Межгород
                                </b-row>
                                <div class="info-modal__text">
                                    Поездки вне города от <span > {{tariff_info.min_price}} смн.</span>
                                    <div>В стоимость поездки входят <span>3</span> км дороги, после <span> {{tariff_info.price_km_intercity}} смн/км</span>.</div>
                                </div>
                                        <b-row class="info-modal__title">
                                            Надбавки
                                        </b-row>
                                        <div class="info-modal__allowances">
                                            <ul v-for="allowance in tariff_info.allowances" :key="allowance.name">
                                            <li>{{allowance.name}} - {{allowance.price}} смн.</li>
                                            
                                            </ul>
                                        </div>
                                     <div class="info-modal__text">
                                        <b>Бесплатное ожидание</b> - <span>{{tariff_info.free_waiting_of_client_in_minute}} мин.</span> , после <span>{{tariff_info.price_hour}}</span> дирам/мин.
                                   </div>
                                 
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-2">
                       <div v-if="editOrder.performer">
                        <div>
                            {{ 'Водитель: ' + editOrder.performer.first_name + ' ' + editOrder.performer.last_name}}
                        </div>
                        <div>
                            {{ 'Логин водителя: ' + editOrder.performer.login}}
                        </div>
                        <div>
                            {{ 'Автомобиль: ' + editOrder.performer.transport.model ? editOrder.performer.transport.model + ' ' + '(' + editOrder.performer.transport.car_number + ')' : '' }}
                        </div>
                       </div>
                       <div v-else>
                        Водитель не назначен
                       </div>
                    </div>
                </b-col>
                <b-col cols="12">
                    <b-row>
                        <b-col cols="6">
                            <label for="">Доп.инфо для водителя:</label>
                        <b-form-textarea
                        :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false"
                        v-model="editOrder.info_for_drivers"
                        placeholder=""
                        />
                        </b-col>
                        <b-col cols="6">
                            <label for="">Доп.инфо для оператора:</label>
                        <b-form-textarea
                        :disabled="(editOrder.status_id == 10 || editOrder.status_id == 9) && positionId == 1 ? true : false"
                        v-model="editOrder.info_for_operator"
                        placeholder=""
                        />
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <template #modal-footer>
                <button v-if="onlyForReading" :disabled="submitButtonDisabled" @click="myFunc" class="btn btn-primary btn-md ">Изменить</button>
                <button v-if="!onlyForReading" :disabled="submitButtonDisabled" @click="$refs['handle_order'].hide(), this.onlyForReading = true" class="btn btn-primary btn-md">Ок</button>
                <button v-if="onlyForReading" @click="$refs['handle_order'].hide()" class="btn btn-secondary btn-md ">Отмена</button>
            </template>
            <b-row>
                <b-modal id="validate" centered :no-close-on-backdrop="true" hide-footer no-enforce-focus>
                    <div>
                        {{ info }}
                    </div>
                    <div class="d-flex justify-content-end">
                        <button variant="primary" @click="closeInfo()">
                            Ок
                        </button>
                    </div>
                </b-modal>
            </b-row>
        </b-modal>
</div>
</template>

<script>
import customSelectAllowance from '../../customSelect/customSelectAllowance.vue'
import vSelect from 'vue-select'
import customSelectEdit from '@/views/component/customSelect/customSelectEdit.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import draggable from 'vuedraggable'
import { mask } from "vue-the-mask"
import SearchAddressVSelect from "../../searchAddressVSelect.vue"


export default {
    directives: { mask },
    components:{
       vSelect,
       customSelectEdit,
       ToastificationContent,
       draggable,
        SearchAddressVSelect,
        customSelectAllowance
   },
    props:['editOrder', 
    // 'defaultAddresses', 
    'orderId', 
    'orderData'
],
data() {
        return {
            searchByDistance:"",
            selected: 'A',
            reason_cancel_order: undefined,
            info_modal: false,
            tariff_info: [],
            positionId: JSON.parse(localStorage.getItem("userData")).role_id,
            indate: false,
            addresses:[],
            checked: false,
            amount_cash: 0,
            tariffsList: [],
            onlyForReading: true,
            info: '',
            defaultAddresses: [],
            formTime: {
                form_name: null,
                start_time: null,
                end_time: null,
            },
            newOrderData: {
                distance_inter_city: null,
                distance_in_city: null, 
                free_km: null,
                price_in_city: null,
                price_inter_city: null,
            },
            drivers: [],
            performer_id: "",
            orderStatus: '',
            statuses: [],
            myFunc:'',
            currentDay: undefined,
            allowances: [],
            newOrder: {
                division_id: 1,
                phone: undefined,
                dop_phone: undefined,
                auto_assignment: 1,
                not_issued: 0,
                for_time: 0,
                date_time: undefined,
                search_address: undefined,
                to_addresses: [{}],
                number_of_passengers: undefined,
                meeting_info: undefined,
                info_for_operator: "",
                info_for_drivers: '',
                order_type_id: 1,
                tariff_id: 1,
                price: 0,
                distance: 0.0,
                allowances: [],
                reasons: []
            },
            submitButtonDisabled: false,
            client_login: null,
            client_bonus_balance: 0
        }
    },
    mounted(){
        this.$root.handle_order_modal = this.$refs.handle_order
        this.myFunc=this.notChange
        this.currentDay = new Date(new Date().getTime() - 86400000)
        this.updated()
    },
    methods: {
        updated() {
            if (this.editOrder.search_address_id == null) {
                this.selected = 'B'
            }
            else if (this.editOrder.search_address_id != null) {
                this.selected = 'A'
            }
        },
        disabledDates(date){
            return date < this.currentDay
        },
        disabledTimes(date){
            return date < new Date (new Date().getTime() + 900000)
        },
        callDriver(){
            if(this.$store.state.caller.status) {
                let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
                this.$http.post('/connect-driver-operator', {
                    number: initiatorNumber,
                    phone: this.editOrder.performer.phone
                })
                .then(() => {
                    this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Запрос на соединение отправлен',
                        icon: 'XIcon',
                        variant: 'success',
                        text: "",
                        },
                    })
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: "Ошибка",
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err,
                        },
                    })
                })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
        },
        callClient(){
            if(this.$store.state.caller.status) {
                let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
                this.$http.post('/connect-driver-operator', {
                    number: initiatorNumber,
                    phone: this.editOrder.phone
                })
                .then(() => {
                    this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Запрос на соединение отправлен',
                        icon: 'XIcon',
                        variant: 'success',
                        text: "",
                        },
                    })
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: "Ошибка",
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err,
                        },
                    })
                })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
        },
        callClientToDopPhone(){
            if(this.$store.state.caller.status) {
                let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
                this.$http.post('/connect-driver-operator', {
                    number: initiatorNumber,
                    phone: this.editOrder.dop_phone
                })
                .then(() => {
                    this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Запрос на соединение отправлен',
                        icon: 'XIcon',
                        variant: 'success',
                        text: "",
                        },
                    })
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: "Ошибка",
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err,
                        },
                    })
                })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
        },
        callAndConnect() {
            if(this.$store.state.caller.status) {
                this.$http.post('/connect-client-performer', {
                    number: this.editOrder.phone,
                    phone: this.editOrder.performer.phone
                })
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Запрос на соединение отправлен',
                        icon: 'XIcon',
                        variant: 'success',
                        text: "",
                        },
                    })
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: "Ошибка",
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err,
                        },
                    })
                })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
        },
        getTariffInfoModal(){
            this.$http
                .get(`/orders/tariff-info/${this.newOrder.tariff_id}`)
                .then(res => {
                    this.tariff_info = res.data
                })
        },
        changeTariff() {
            this.$http
                .get(`orders/allowances`, {params: {tariff_id: this.newOrder.tariff_id}})
                .then(res => {
                    this.allowances = res.data
                })
            this.newOrder.allowances = []
            this.calcPrice()
            this.getTariffInfoModal()
        },
        detectEndTime() {
            this.formTime.end_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            this.$http.post('time-form', this.formTime)
            .then((res) => {
            })
            .catch((err) => {
            }) 
        },
        detectStartTime() {
            this.formTime.form_name = 'orderHandleForm'
            this.formTime.start_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        },
        getTariffs() {
            this.$http
            .get('orders/tariff-list', {params: {division_id: this.editOrder.division_id}})
            .then(res => {
                this.tariffsList = res.data
            })
        },
        getData(){
            this.checked = this.editOrder.active_bonus ? true : false
            if(this.editOrder.message) {
                this.info = this.editOrder.message + '  ' + 'Эта карточка доступна только для просмотра'
                this.$bvModal.show('validate')
                this.onlyForReading = false
            }else{
            this.getTariffs(this.editOrder.division_id)
            this.$http.get('settings-order/cancel-reason')
            .then((res) => {
                this.reasons = res.data
            })
            this.calcPrice()
            let calcData = {
                allowances: this.editOrder.allowances,
                search_address_id: this.editOrder.from_address && this.editOrder.from_address.id ? this.editOrder.from_address.id : undefined,
                tariff_id: this.editOrder.tariff_id,
                to_addresses: [],
                client_phone: undefined,
                division_id: this.editOrder.division_id,
                order_type: this.editOrder.order_type_id,
                active_bonus: this.checked ? 1 : 0
            };

            calcData.allowances = JSON.stringify(calcData.allowances);
            calcData.to_addresses = JSON.stringify(calcData.to_addresses);
            
            this.$http.post('calculate', calcData)
                .then((res) => {
                    this.editOrder.price=res.data.amount
                    this.editOrder.distance=res.data.distance
                    this.editOrder.order_type_id=res.data.order_type
                    this.editOrder.bonus = res.data.bonus_price
                    this.amount_cash = res.data.amount_cash
                    this.client_login = res.data.client_login
                    this.client_bonus_balance = res.data.client_bonus_balance
                    this.newOrderData.distance_in_city = res.data.distance_in_city
                    this.newOrderData.free_km = res.data.free_km
                    this.newOrderData.distance_inter_city = res.data.distance - res.data.distance_in_city
                    this.newOrderData.distance_inter_city = this.newOrderData.distance_inter_city.toFixed(2)
                }) 
                .catch((err) => {
                    (err)
                })
            this.$http
            .get(`drivers/free-driver?order_id=${this.orderId}`)
            .then(res => { 
                this.drivers=res.data
            })
            .catch((err) => {
                this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.message,
                    },
                })
            })
            this.$http
            .get(`orders/${this.orderId}/active-status`)
            .then(res =>{
                this.orderStatus = this.editOrder.status_id
                let arrStatuses = []
                arrStatuses.push({name: this.editOrder.status, id: this.editOrder.status_id})
                let status = res.data
                if (status.length > 0) {
                    status.forEach(el => {
                        arrStatuses.push({name: el.name, id: el.id})
                    })
                }
                this.statuses = arrStatuses
            })
            this.$http.get(`orders/allowances?tariff_id=${this.editOrder.tariff_id}`).then(res => { 
                this.allowances = res.data
            })
            this.$http
            .post('directory/search-addresses')
            .then(res => { 
                this.defaultAddresses = res.data
            })
            this.getTariffInfoModal()
            }
        },
        GetAllowances(allowances){
            this.editOrder.allowances = allowances
            this.calcPrice()
        },
        asignment(){
            this.myFunc=this.asign_driver
        },
        rollback(){
            this.myFunc=this.driver_rollback
        },
        status(){
            this.myFunc=this.changeStatus
        },
        notChangeStatus(){
            this.myFunc=this.notChange
        },
        notChange(){
            this.updateOrder()
        },
        changeStatus(){
            if (this.editOrder.status_id == this.orderStatus || !this.orderStatus) {
                this.orderStatus = undefined
                this.$refs['handle_order'].hide()
            }else {
                if (this.editOrder.reason_cancel_order == 9) {
                    this.editOrder.reason_cancel_order = this.reason_cancel_order
                }
                let status_id = this.orderStatus
                let order_id=this.editOrder.id
                let reason_cancel_order = this.editOrder.reason_cancel_order ? this.editOrder.reason_cancel_order : undefined
                this.submitButtonDisabled = true
                this.$http
                .post('edit-order-status', {status_id: status_id, order_id: order_id, reason_cancel_order: String(reason_cancel_order)})
                .then(res => {
                    this.updateOrder()
                        this.$refs['handle_order'].hide()
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Успех!',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                        })
                        this.$emit('refresh')
                    })
                    .catch(err => {
                        let error=err.response
                                var toastText=error.data.message
                            this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: toastText,
                            },
                        })
                    }).finally(()=>{
                        this.submitButtonDisabled = false
                    })
            }
        },
        driver_rollback(){
            let order_id=this.editOrder.id
            this.submitButtonDisabled = true
            this.$http
            .post('order-rollback', {order_id: order_id})
            .then(res => {
                this.updateOrder()
                    this.$refs['handle_order'].hide()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    let error=err.response
                            var toastText=error.data.message
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Ошибка!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: toastText,
                        },
                    })
                }).finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        asign_driver(){
               let performer_id=this.performer_id
              let order_id=this.editOrder.id
            this.submitButtonDisabled = true
              this.$http
            .post('order-destination', {performer_id: performer_id, order_id: order_id})
            .then(res => {
                this.updateOrder()
                    this.$refs['handle_order'].hide()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    let error=err.response
                            var toastText=error.data.message
                        this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: toastText,
                        },
                    })
                }).finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        calcPrice() {
            let calcData = {
                allowances: this.editOrder.allowances,
                search_address_id: this.editOrder.from_address && this.editOrder.from_address.id ? this.editOrder.from_address.id : undefined,
                tariff_id: this.editOrder.tariff_id,
                to_addresses: [],
                client_phone: undefined,
                division_id: this.editOrder.division_id,
                order_type: this.editOrder.order_type_id,
                active_bonus: this.checked ? 1 : 0
            };
            // calcData.client_phone = calcData.client_phone ? calcData.client_phone : undefined
            calcData.allowances = JSON.stringify(calcData.allowances);
            this.editOrder.to_addresses.forEach(el => {
                if(el) {
                    calcData.to_addresses.push({ search_address_id: el.id })
                }
            })
            calcData.to_addresses = JSON.stringify(calcData.to_addresses)
            this.$http.post('calculate', calcData)
            .then((res) => {
                this.editOrder.price=res.data.amount
                this.editOrder.distance=res.data.distance
                this.editOrder.order_type_id=res.data.order_type
                this.editOrder.bonus = res.data.bonus_price
                this.amount_cash = res.data.amount_cash
                this.client_login = res.data.client_login
                this.client_bonus_balance = res.data.client_bonus_balance
                this.newOrderData.distance_in_city = res.data.distance_in_city
                this.newOrderData.free_km = res.data.free_km
                this.newOrderData.distance_inter_city = res.data.distance - res.data.distance_in_city
                this.newOrderData.distance_inter_city = this.newOrderData.distance_inter_city.toFixed(2)
            }) 
            .catch((err) => {
            })
        },
        notIssued() {
            if (this.selected == 'A') {
                this.editOrder.auto_assignment = 1
                this.editOrder.not_issued = 0
            }
            else if (this.selected == 'B') {
                this.editOrder.auto_assignment = 0
                this.editOrder.not_issued = 1
            }
        },
        Intime(){
            this.editOrder.for_time=!this.editOrder.for_time
        },
        updateOrder() {
            this.editOrder.allowances.forEach(el => {
                if (el.type || el.name) {
                    delete el.name
                    delete el.type 
                }
            })
            let orderEditJSON = JSON.parse(JSON.stringify(this.editOrder))
            if (orderEditJSON.from_address) {
                orderEditJSON.search_address_id = orderEditJSON.from_address.id
                orderEditJSON.from_address = undefined
            } else {
                orderEditJSON.search_address_id = undefined
            }
            orderEditJSON.to_addresses = []
            for (let address of this.editOrder.to_addresses) {0
                if (address) {
                    if (address.id) {
                        orderEditJSON.to_addresses.push({search_address_id: address.id})
                    }      
                }
            }
            for (let i of orderEditJSON.allowances) {
                if (i.allowance_id == null) {
                    i.allowance_id = undefined
                }
                delete i.name
            }
            orderEditJSON.number_of_passengers = undefined
            orderEditJSON.to_addresses = JSON.stringify(orderEditJSON.to_addresses)
            orderEditJSON.allowances = orderEditJSON.allowances.length > 0 ? JSON.stringify(orderEditJSON.allowances) : undefined
            this.submitButtonDisabled = true
            this.$http
                .patch(`orders/${this.editOrder.id}`, orderEditJSON)
                .then(res => {
                    this.$refs['handle_order'].hide()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Заказ успешно изменен!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                let error = err.response.data.errors
                for (let e in error) {
                    var toastText = error[e][0]
                }
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: toastText,
                    },
                })
            }).finally(()=>{
                this.submitButtonDisabled = false
            })
        },
        openMemosForm() {
            this.$bvModal.show('memosCreateModal')
        },
      routePush(){
                this.editOrder.to_addresses.push(null)
                if (this.editOrder.to_addresses.length > 1) {
                    this.disabled = false
                } else {
                    this.disabled = true
                }
            },
            routeMinus(id){
                this.editOrder.to_addresses.splice(id, 1);
                if (this.editOrder.to_addresses.length > 1) {
                    this.disabled = false
                } else {
                    this.disabled = true
                }
                this.calcPrice()
            },
            ableDisableInfoModal(){
               this.info_modal = !this.info_modal 
            },
  },
  closeInfo(){
    this.$bvModal.hide('validate')
  },
  computed: {
        hasPerformer() {
            if (this.editOrder.performer) {
                return true
            }
            return false
        }
   },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@media (min-width: 992px){
 .modal-lg {
    max-width: 1000px !important;
   }
}
@media (min-width: 576px){
  .modal-dialog {
    max-width: auto !important;

  }
}
  .list-group-item {
    transition: all 1s
}
.vs__dropdown-menu {
    z-index: 9999 !important;
}
    /* width */
.address::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.address::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(156, 155, 155); 
    border-radius: 10px;
}
 
/* Handle */
.address::-webkit-scrollbar-thumb {
    background: rgba(150, 150, 150, 0.924); 
    border-radius: 10px;
}

/* Handle on hover */
.address::-webkit-scrollbar-thumb:hover {
    background: rgb(166, 166, 169);  
}
.handle-container {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 10px;
}

.info-modal__interior{
    position: fixed;
    z-index: 1000;
    top:23px;
    left: 25px;
    max-width: 350px;
    min-height:565px;
    background: rgb(255, 253, 253);
    padding: 19.6px;
    border-radius: 4px;
}

.info-modal__tariff{
    margin-top: 5px;
    font-weight: bold;
}

.info-modal__title{
    margin: 8px 0 8px 0;
    font-weight: bold;
    margin: 0 auto;
    min-width: 100%;
}
.info-modal__text{
    margin: 0 auto;
    margin: 8px 0 8px 0;
}
.info-modal__allowances{
    margin-top: 10px;
    line-height: 10px;
}

.order-content{
      margin-left: 30%;
  }

  @media (min-width:1780px){
    .info-modal__interior{
        left: 4%;
    }
.order-content{
      margin-left: 25%;
  }
}
@media (min-width:1580px){
    .info-modal__interior{
         margin-left: 4%;
    }
.order-content{
      margin-left: 15%;
  }
}
@media (max-width:1580px){
    .info-modal__interior{
         margin-left: 0%;
    }
    .order-content{
      margin-left: 200px;
  }
}
@media (max-width:1450px){
  .order-content{
      max-width: 920px;
}
}
@media (max-width:1359px){
  .order-content{
      margin-left: inherit;
}
}

@media (min-width:1900px){
    .info-modal__interior{
         max-width: 500px;
    }
}

@media (min-width:1780px){
    .info-modal__interior{
        left: 4%;
    }
}

@media (min-width:1580px){
    .info-modal__interior{
         margin-left: 4%;
    }
}

@media (max-width:1580px){
    .info-modal__interior{
         margin-left: 0%;
    }
}

.bonus {
    display: flex;
    justify-content: space-around;
    background: yellow;
    margin: 0;
    padding: 0;
}

.bonus p {
    margin: 0;
    padding: 0;
}
</style>