<template>
    <div>
        <b-modal
        :id="$route.name + 'CreateModal'"
        title="Добавить шаблон"
        size="sm"
        centered
        hide-footer
        no-enforce-focus
        :no-close-on-backdrop="true"
        @close="close"
        >
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Название шаблона <i class="required" >*</i></label>
                <b-input v-model="messageTemplate.title" placeholder="Название шаблона" />
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Описание шаблона <i class="required" >*</i></label>
                <b-textarea v-model="messageTemplate.description" placeholder="Описание шаблона" ></b-textarea>
            </b-col>
        </b-row>
        <b-row class="mb-1 mt-2">
            <b-col class="d-flex justify-content-between">
                <b-button @click="close()"  variant="danger">Отмена</b-button>
                <b-button @click="create()" :disabled="submitButtonDisabled"  variant="primary">Добавить</b-button>
            </b-col>
        </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent
    },
    data() {
        return {
            messageTemplate: {
                title: null,
                description: null,
                type: "AppVersion"
            },
            submitButtonDisabled: false
        }
    },
    methods: {
        close() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.cleardata()
        },
        cleardata() {
            this.messageTemplate = {
                title: null,
                description: null,
                type: "AppVersion"
            }
        },
        create() {
            this.submitButtonDisabled = true
            this.$http.post('message-templates', this.messageTemplate)
            .then((res) => {
                this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Шаблон cоздан!',
						icon: 'XIcon',
						variant: 'success',
						text: res.data.message,
					},
				});
                this.close()
                this.$emit('refresh')
            })
            .catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: error.response.data.errors,
                    },
                });
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.required {
    color: red;
}
</style>