export const deleteMapContributors = () => {
    let contributors = document.getElementsByClassName('maplibregl-control-container')
    if(contributors) {
        contributors[0].style.display = "none"
    }
}

export const getSvgWithColor = (color, is_status, direction) => {
    return is_status == 'performer' ? car(color, direction) : `<svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="16" cy="16" r="14" stroke="black" stroke-width="3" fill="${color}" />
</svg>`
}

export const svgStringToImageSrc = (svgString) => {
    return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString);
}


const car = (color, direction) => {
    const from = `${direction} 12 21`
    const to = `${direction} 21 12`
    return `<svg width="24" height="42" viewBox="0 0 24 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_6106_36860)">
<path d="M19.9462 7.3007L19.8653 5.99106C19.7911 4.81101 19.4271 3.66507 18.7731 2.68965C17.573 0.90935 15.8202 0.193137 13.238 0.0908203L11.1953 0.0908203C8.61987 0.193137 6.86025 0.90935 5.66694 2.68965C5.01298 3.66507 4.64892 4.81101 4.57476 5.99106L4.49386 7.3007C4.43992 8.14652 4.4534 8.99915 4.54105 9.84497L4.56127 10.036L4.56127 31.3382C4.61521 32.5796 4.83769 33.2276 5.22872 34.2508C5.9501 35.9424 7.45353 36.6996 11.5054 36.72H12.9414C16.9932 36.6996 18.4967 35.9356 19.2181 34.2508C19.6091 33.2276 19.8248 32.5796 19.8855 31.3382V10.0428L19.9057 9.85179C19.9799 9.00597 19.9934 8.15334 19.9462 7.3007ZM18.4293 11.0046L17.5326 15.6429C17.4719 15.9567 17.418 16.2704 17.3708 16.5842C13.3998 15.7247 11.0065 15.6361 6.98834 16.5842C6.94115 16.2704 6.88721 15.9567 6.82654 15.6429L5.92987 11.0046C5.73436 10.0019 6.26696 8.99915 7.20408 8.62399C8.44458 8.12605 9.8806 7.90778 11.802 7.85321H12.5436C14.4651 7.90778 15.9078 8.13288 17.1416 8.63081C18.0922 8.99915 18.6248 10.0019 18.4293 11.0046Z" fill="${color}"/>
<path d="M8.51206 1.10034L7.87158 8.3989H7.95248L8.84915 1.14127C8.69409 1.14127 8.57948 1.14809 8.51206 1.10034Z" fill="#E7E7E7"/>
<path d="M15.9217 1.10034L16.5621 8.3989H16.4812L15.5913 1.14127C15.7396 1.14127 15.8542 1.14809 15.9217 1.10034Z" fill="#E7E7E7"/>
<path d="M17.9844 30.09C17.9844 30.9085 17.8968 31.5702 17.6945 32.1022C17.681 32.0476 17.6541 31.9931 17.6204 31.9385C17.5462 32.1295 17.4653 32.3068 17.3844 32.4774C17.1147 33.0162 16.636 33.4118 16.0697 33.6028C15.1798 33.8961 14.3438 34.0053 13.191 34.0871C12.9011 34.1076 12.6179 34.1144 12.328 34.1144H12.1392C11.8561 34.1144 11.5729 34.1076 11.2965 34.0871C10.1369 34.0053 9.30091 33.8961 8.40424 33.6028C7.83793 33.4187 7.366 33.0162 7.08958 32.4774C7.00194 32.3068 6.92778 32.1295 6.84688 31.9317C6.81991 31.9726 6.79968 32.0135 6.77946 32.0613C6.57046 31.5361 6.47607 30.888 6.47607 30.09C6.47607 29.7421 6.4963 29.3874 6.53001 29.0327H17.9237C17.9709 29.3942 17.9844 29.7421 17.9844 30.09Z" fill="#4B545A"/>
<path d="M17.9844 30.09C17.9844 30.9085 17.8968 31.5702 17.6945 32.1022C17.681 32.0476 17.6541 31.9931 17.6204 31.9385C17.5462 32.1295 17.4653 32.3068 17.3844 32.4774C17.1147 33.0162 16.636 33.4118 16.0697 33.6028C15.1798 33.8961 14.3438 34.0053 13.191 34.0871C12.9011 34.1076 12.6179 34.1144 12.328 34.1144H12.1392C11.8561 34.1144 11.5729 34.1076 11.2965 34.0871C10.1369 34.0053 9.30091 33.8961 8.40424 33.6028C7.83793 33.4187 7.366 33.0162 7.08958 32.4774C7.00194 32.3068 6.92778 32.1295 6.84688 31.9317C6.81991 31.9726 6.79968 32.0135 6.77946 32.0613C6.57046 31.5361 6.47607 30.888 6.47607 30.09C6.47607 29.7421 6.4963 29.3874 6.53001 29.0327H17.9237C17.9709 29.3942 17.9844 29.7421 17.9844 30.09Z" fill="#4388FC" fill-opacity="0.1"/>
<path d="M17.9844 30.09C17.9844 30.9085 17.8968 31.5702 17.6945 32.1022C17.681 32.0476 17.6541 31.9931 17.6204 31.9385C17.5462 32.1295 17.4653 32.3068 17.3844 32.4774C17.1147 33.0162 16.636 33.4118 16.0697 33.6028C15.1798 33.8961 14.3438 34.0053 13.191 34.0871C12.9011 34.1076 12.6179 34.1144 12.328 34.1144H12.1392C11.8561 34.1144 11.5729 34.1076 11.2965 34.0871C10.1369 34.0053 9.30091 33.8961 8.40424 33.6028C7.83793 33.4187 7.366 33.0162 7.08958 32.4774C7.00194 32.3068 6.92778 32.1295 6.84688 31.9317C6.81991 31.9726 6.79968 32.0135 6.77946 32.0613C6.57046 31.5361 6.47607 30.888 6.47607 30.09C6.47607 29.7421 6.4963 29.3874 6.53001 29.0327H17.9237C17.9709 29.3942 17.9844 29.7421 17.9844 30.09Z" fill="url(#paint0_radial_6106_36860)" fill-opacity="0.77"/>
<path d="M6.73227 27.2523C6.71878 27.8798 6.7053 28.5279 6.69182 29.2441C6.69182 29.2986 6.67833 29.3532 6.65136 29.401C6.53675 29.6192 6.37495 29.6943 6.11876 29.6465C5.74796 29.5305 5.55918 29.1827 5.5457 28.5688L5.18164 11.4274C5.18164 11.4138 5.18164 11.4001 5.18164 11.3865C5.18838 11.3933 5.19512 11.4069 5.20187 11.4206L5.39738 11.8026C5.43783 11.8844 5.47154 11.9731 5.49177 12.0686L6.0446 15.0153C6.43563 17.1025 6.64462 18.9511 6.67159 20.6836C6.81317 23.337 6.77946 25.1514 6.73227 27.2523Z" fill="#4B545A"/>
<path d="M6.73227 27.2523C6.71878 27.8799 6.7053 28.5279 6.69182 29.2441C6.69182 29.2986 6.67833 29.3532 6.65136 29.401C6.53675 29.6192 6.37495 29.6943 6.11876 29.6465C5.74796 29.5306 5.55918 29.1827 5.5457 28.5688L5.18164 11.4274C5.18164 11.4138 5.18164 11.4001 5.18164 11.3865C5.18838 11.3933 5.19512 11.4069 5.20187 11.4206L5.39738 11.8026C5.43783 11.8844 5.47154 11.9731 5.49177 12.0686L6.0446 15.0153C6.43562 17.1025 6.64462 18.9511 6.67159 20.6836C6.81317 23.337 6.77946 25.1514 6.73227 27.2523Z" fill="#4388FC" fill-opacity="0.1"/>
<path d="M6.73227 27.2523C6.71878 27.8799 6.7053 28.5279 6.69182 29.2441C6.69182 29.2986 6.67833 29.3532 6.65136 29.401C6.53675 29.6192 6.37495 29.6943 6.11876 29.6465C5.74796 29.5306 5.55918 29.1827 5.5457 28.5688L5.18164 11.4274C5.18164 11.4138 5.18164 11.4001 5.18164 11.3865C5.18838 11.3933 5.19512 11.4069 5.20187 11.4206L5.39738 11.8026C5.43783 11.8844 5.47154 11.9731 5.49177 12.0686L6.0446 15.0153C6.43562 17.1025 6.64462 18.9511 6.67159 20.6836C6.81317 23.337 6.77946 25.1514 6.73227 27.2523Z" fill="url(#paint1_radial_6106_36860)" fill-opacity="0.77"/>
<path d="M5.40422 21.7955C5.84244 22.041 6.16605 22.1297 6.73911 22.1979L6.69866 21.1543C6.18628 20.8746 5.80873 20.6018 5.36377 20.1448L5.40422 21.7955Z" fill="#4B545A"/>
<path d="M5.76828 13.5283L5.22893 13.3851L5.18848 11.4274C5.18848 11.4138 5.18848 11.4001 5.18848 11.3865C5.19522 11.3933 5.20196 11.4069 5.2087 11.4206L5.40422 11.8026C5.44467 11.8844 5.47838 11.9731 5.4986 12.0686L5.76828 13.5283Z" fill="#4B545A"/>
<path d="M17.708 27.2523C17.7215 27.8798 17.7349 28.5279 17.7484 29.2441C17.7484 29.2986 17.7619 29.3532 17.7889 29.401C17.9035 29.6192 18.0653 29.6943 18.3215 29.6465C18.6923 29.5305 18.8811 29.1827 18.8945 28.5688L19.2586 11.4274C19.2586 11.4138 19.2586 11.4001 19.2586 11.3865C19.2519 11.3933 19.2451 11.4069 19.2384 11.4206L19.0429 11.8026C19.0024 11.8844 18.9687 11.9731 18.9485 12.0686L18.3889 15.0289C17.9979 17.1162 17.7889 18.9647 17.7619 20.6973C17.6203 23.337 17.6608 25.1514 17.708 27.2523Z" fill="#4B545A"/>
<path d="M17.708 27.2523C17.7215 27.8799 17.7349 28.5279 17.7484 29.2441C17.7484 29.2986 17.7619 29.3532 17.7889 29.401C17.9035 29.6192 18.0653 29.6943 18.3215 29.6465C18.6923 29.5306 18.8811 29.1827 18.8945 28.5688L19.2586 11.4274C19.2586 11.4138 19.2586 11.4001 19.2586 11.3865C19.2519 11.3933 19.2451 11.4069 19.2384 11.4206L19.0429 11.8026C19.0024 11.8844 18.9687 11.9731 18.9485 12.0686L18.3889 15.0289C17.9979 17.1162 17.7889 18.9647 17.7619 20.6973C17.6203 23.337 17.6608 25.1514 17.708 27.2523Z" fill="#4388FC" fill-opacity="0.1"/>
<path d="M17.708 27.2523C17.7215 27.8799 17.7349 28.5279 17.7484 29.2441C17.7484 29.2986 17.7619 29.3532 17.7889 29.401C17.9035 29.6192 18.0653 29.6943 18.3215 29.6465C18.6923 29.5306 18.8811 29.1827 18.8945 28.5688L19.2586 11.4274C19.2586 11.4138 19.2586 11.4001 19.2586 11.3865C19.2519 11.3933 19.2451 11.4069 19.2384 11.4206L19.0429 11.8026C19.0024 11.8844 18.9687 11.9731 18.9485 12.0686L18.3889 15.0289C17.9979 17.1162 17.7889 18.9647 17.7619 20.6973C17.6203 23.337 17.6608 25.1514 17.708 27.2523Z" fill="url(#paint2_radial_6106_36860)" fill-opacity="0.77"/>
<path d="M19.0361 21.8023C18.5978 22.0479 18.2742 22.1365 17.7012 22.2048L17.7416 21.1611C18.254 20.8815 18.6315 20.6086 19.0765 20.1516L19.0361 21.8023Z" fill="#4B545A"/>
<path d="M18.6724 13.5351L19.2117 13.3919L19.2522 11.4342C19.2522 11.4206 19.2522 11.407 19.2522 11.3933C19.2454 11.4001 19.2387 11.4138 19.2319 11.4274L19.0364 11.8094C18.996 11.8913 18.9623 11.9799 18.942 12.0754L18.6724 13.5351Z" fill="#4B545A"/>
<path d="M5.41114 12.3689C5.45159 12.5326 5.44485 12.6281 5.41114 12.785H4.85156V12.3689H5.41114Z" fill="#594F4F"/>
<path d="M4.94571 12.4847L4.93897 13.0577C4.93897 13.1191 4.89851 13.1737 4.83784 13.1941L3.3816 13.6852C3.3344 13.6989 3.28047 13.6716 3.26699 13.6239C3.24676 13.5488 3.22654 13.467 3.21979 13.3647C3.21305 13.2964 3.21979 13.2282 3.21979 13.1737C3.23328 13.0304 3.28721 12.894 3.37486 12.7849C3.45576 12.6894 3.55689 12.5939 3.71195 12.5188C3.90746 12.4233 4.17039 12.3688 4.43333 12.362C4.55468 12.3551 4.70974 12.362 4.8311 12.3688C4.89851 12.3688 4.94571 12.4233 4.94571 12.4847Z" fill="#594F4F"/>
<path d="M19.0225 12.3694C18.982 12.5331 18.9888 12.6286 19.0225 12.7855H19.5821V12.3694H19.0225Z" fill="#594F4F"/>
<path d="M19.4878 12.4916L19.4945 13.0645C19.4945 13.1259 19.535 13.1805 19.5957 13.201L21.0519 13.6921C21.0991 13.7057 21.153 13.6784 21.1665 13.6307C21.1867 13.5557 21.207 13.4738 21.2137 13.3715C21.2204 13.3033 21.2137 13.2351 21.2137 13.1805C21.2002 13.0373 21.1463 12.9008 21.0586 12.7917C20.9777 12.6962 20.8766 12.6007 20.7215 12.5257C20.526 12.4302 20.2631 12.3756 20.0002 12.3688C19.8788 12.362 19.7238 12.3688 19.6024 12.3756C19.535 12.3756 19.4878 12.4234 19.4878 12.4916Z" fill="#594F4F"/>
<path d="M8.00667 1.34595C6.58414 2.22587 5.78186 3.25585 5.37061 4.89291V4.07438C5.37061 3.59008 5.53241 3.12625 5.84254 2.75791C6.4493 2.04852 7.04258 1.58469 8.00667 1.34595Z" fill="url(#paint3_linear_6106_36860)"/>
<path d="M8.00667 1.34595C6.58414 2.22587 5.78186 3.25585 5.37061 4.89291V4.07438C5.37061 3.59008 5.53241 3.12625 5.84254 2.75791C6.4493 2.04852 7.04258 1.58469 8.00667 1.34595Z" fill="url(#paint4_linear_6106_36860)"/>
<path d="M5.37744 4.88609V4.07438C5.37744 3.59008 5.53925 3.12625 5.84937 2.75791C6.4494 2.04852 7.04268 1.58469 8.00676 1.34595C6.58423 2.22587 5.78195 3.24903 5.37744 4.88609Z" fill="#D5E3F0"/>
<path d="M16.3662 1.34595C17.7887 2.22587 18.591 3.25585 19.0023 4.89291V4.07438C19.0023 3.59008 18.8405 3.12625 18.5303 2.75791C17.9303 2.04852 17.3303 1.58469 16.3662 1.34595Z" fill="url(#paint5_linear_6106_36860)"/>
<path d="M16.3662 1.34595C17.7887 2.22587 18.591 3.25585 19.0023 4.89291V4.07438C19.0023 3.59008 18.8405 3.12625 18.5303 2.75791C17.9303 2.04852 17.3303 1.58469 16.3662 1.34595Z" fill="url(#paint6_linear_6106_36860)"/>
<path d="M19.0023 4.88607V4.07436C19.0023 3.59007 18.8405 3.12623 18.5303 2.7579C17.9303 2.04168 17.3303 1.57785 16.3662 1.33911C17.7887 2.22585 18.591 3.24901 19.0023 4.88607Z" fill="#D5E3F0"/>
<path d="M7.85165 36.3652C6.40215 35.9832 5.66729 35.2943 5.22233 34.2575C5.10772 33.9573 5.00659 33.6845 4.91895 33.4253L5.05378 33.4662C5.50549 33.6095 5.86955 33.9437 6.05832 34.3802C6.3617 35.0896 6.67857 35.3966 7.59546 35.8741C7.77749 35.9696 7.87862 36.1674 7.85165 36.3652Z" fill="#D8394C"/>
<path d="M16.582 36.3652C18.0314 35.9832 18.7663 35.2943 19.2113 34.2575C19.3259 33.9573 19.427 33.6845 19.5147 33.4253L19.3798 33.4662C18.9281 33.6095 18.5641 33.9437 18.3753 34.3802C18.0719 35.0896 17.755 35.3966 16.8381 35.8741C16.6561 35.9696 16.555 36.1674 16.582 36.3652Z" fill="#D8394C"/>
<path d="M12.5508 9.51744C14.5194 9.572 15.9218 9.81074 17.0881 10.2814C17.5937 10.486 17.9848 10.8953 18.1735 11.4069L17.3578 15.6087C17.3106 15.861 17.2634 16.1202 17.2229 16.3726C15.0992 15.9224 13.5486 15.7314 12.0722 15.7314C10.5755 15.7314 9.03832 15.9293 7.13038 16.3726C7.08993 16.1202 7.04948 15.861 6.99554 15.6087L6.18652 11.4069C6.37529 10.8953 6.76632 10.486 7.27196 10.2814C8.44504 9.81074 9.8406 9.57883 11.8092 9.51744H12.5508ZM12.5508 9.34009H11.8092C9.8878 9.39466 8.44504 9.61975 7.21128 10.1109C6.63148 10.3428 6.2 10.8203 6.01123 11.3864L6.83374 15.6428C6.89441 15.9565 6.94835 16.2703 6.99554 16.5841C8.95742 16.1202 10.5283 15.9088 12.0856 15.9088C13.7239 15.9088 15.342 16.1475 17.3847 16.5841C17.4319 16.2703 17.4859 15.9565 17.5465 15.6428L18.369 11.3932C18.1735 10.8271 17.7488 10.3496 17.169 10.1177C15.915 9.61975 14.4723 9.39466 12.5508 9.34009Z" fill="black"/>
<path d="M18.3555 11.3932L17.533 15.6428C17.4723 15.9565 17.4183 16.2703 17.3712 16.5841C13.4002 15.7246 11.0068 15.636 6.98871 16.5841C6.94151 16.2703 6.88758 15.9565 6.8269 15.6428L6.00439 11.3932C6.19991 10.8271 6.62464 10.3496 7.20444 10.1177C8.44494 9.61977 9.88096 9.40149 11.8024 9.34692H12.544C14.4654 9.40149 15.9082 9.62659 17.1419 10.1245C17.7352 10.3496 18.16 10.8271 18.3555 11.3932Z" fill="#4B545A"/>
<path d="M18.3555 11.3932L17.533 15.6428C17.4723 15.9565 17.4184 16.2703 17.3712 16.5841C13.4002 15.7246 11.0068 15.636 6.98871 16.5841C6.94151 16.2703 6.88758 15.9565 6.8269 15.6428L6.00439 11.3932C6.19991 10.8271 6.62464 10.3496 7.20444 10.1177C8.44495 9.61977 9.88096 9.40149 11.8024 9.34692H12.544C14.4654 9.40149 15.9082 9.62659 17.1419 10.1245C17.7352 10.3496 18.16 10.8271 18.3555 11.3932Z" fill="#4388FC" fill-opacity="0.1"/>
<path d="M18.3555 11.3932L17.533 15.6428C17.4723 15.9565 17.4184 16.2703 17.3712 16.5841C13.4002 15.7246 11.0068 15.636 6.98871 16.5841C6.94151 16.2703 6.88758 15.9565 6.8269 15.6428L6.00439 11.3932C6.19991 10.8271 6.62464 10.3496 7.20444 10.1177C8.44495 9.61977 9.88096 9.40149 11.8024 9.34692H12.544C14.4654 9.40149 15.9082 9.62659 17.1419 10.1245C17.7352 10.3496 18.16 10.8271 18.3555 11.3932Z" fill="url(#paint7_radial_6106_36860)" fill-opacity="0.77"/>
<path d="M18.4294 11.0046C18.6249 10.0019 18.0923 8.9992 17.1552 8.62404C15.9147 8.1261 14.4787 7.90783 12.5573 7.84644H11.8157C9.89424 7.901 8.45148 8.1261 7.21772 8.61722C6.28061 8.99238 5.748 9.99508 5.94352 10.9978L6.01768 11.3866C6.21319 10.8204 6.63793 10.343 7.21772 10.111C8.45823 9.6131 9.89424 9.39482 11.8157 9.34025H12.5573C14.4787 9.39482 15.9215 9.61992 17.1552 10.1179C17.735 10.3498 18.1665 10.8272 18.3553 11.3934L18.4294 11.0046Z" fill="#601D1D"/>
<path d="M15.8608 0.984153C15.8608 0.990974 15.8338 1.00462 15.7597 1.00462L8.68072 1.00462C8.60656 1.00462 8.57959 0.984153 8.57959 0.984153C8.57959 0.977332 8.59307 0.936405 8.67398 0.881836L8.73465 0.84091C9.09197 0.58853 9.3549 0.404361 9.79312 0.227013C10.2313 0.158802 10.6965 0.117875 11.1954 0.0974121L13.2382 0.0974121C13.7371 0.117875 14.2023 0.158802 14.6405 0.227013C14.6405 0.227013 14.6405 0.227013 14.6473 0.227013C15.0855 0.404361 15.3484 0.58853 15.7057 0.84091L15.7664 0.881836C15.8473 0.936405 15.8608 0.977332 15.8608 0.984153Z" fill="#4B545A"/>
<path d="M6.69212 28.1936H17.7488C17.7488 28.1936 17.838 28.996 17.3779 29.8648C16.3667 31.7747 7.80452 31.6724 7.02921 29.8648C6.64141 28.9606 6.69212 28.1936 6.69212 28.1936Z" fill="#722424"/>
<animateTransform 
      attributeType="XML" 
      attributeName="transform" 
      type="rotate" 
      from="${from}" 
      to="${to}" />
</g>
<defs>
<filter id="filter0_d_6106_36860" x="0.645369" y="0.0908203" width="23.1429" height="41.7857" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2.57143"/>
<feGaussianBlur stdDeviation="1.28571"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6106_36860"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6106_36860" result="shape"/>
</filter>
<radialGradient id="paint0_radial_6106_36860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.5235 28.6712) rotate(90) scale(5.83202 19.0463)">
<stop stop-color="white" stop-opacity="0.8"/>
<stop offset="1" stop-color="white" stop-opacity="0.01"/>
</radialGradient>
<radialGradient id="paint1_radial_6106_36860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.98886 31.0897) rotate(90.033) scale(19.9735 2.50902)">
<stop stop-color="white" stop-opacity="0.8"/>
<stop offset="1" stop-color="white" stop-opacity="0.01"/>
</radialGradient>
<radialGradient id="paint2_radial_6106_36860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.4729 31.0897) rotate(90.0331) scale(19.9735 2.51389)">
<stop stop-color="white" stop-opacity="0.8"/>
<stop offset="1" stop-color="white" stop-opacity="0.01"/>
</radialGradient>
<linearGradient id="paint3_linear_6106_36860" x1="5.37453" y1="3.11577" x2="8.00982" y2="3.11577" gradientUnits="userSpaceOnUse">
<stop offset="0.1548" stop-color="#A1A1A1"/>
<stop offset="0.9205" stop-color="white"/>
</linearGradient>
<linearGradient id="paint4_linear_6106_36860" x1="5.37453" y1="3.11577" x2="8.00982" y2="3.11577" gradientUnits="userSpaceOnUse">
<stop stop-color="#333333"/>
<stop offset="0.1883"/>
<stop offset="0.3849" stop-color="#333333"/>
<stop offset="0.5983"/>
<stop offset="0.8033" stop-color="#333333"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint5_linear_6106_36860" x1="19.003" y1="3.11577" x2="16.3677" y2="3.11577" gradientUnits="userSpaceOnUse">
<stop offset="0.1548" stop-color="#A1A1A1"/>
<stop offset="0.9205" stop-color="white"/>
</linearGradient>
<linearGradient id="paint6_linear_6106_36860" x1="19.003" y1="3.11577" x2="16.3677" y2="3.11577" gradientUnits="userSpaceOnUse">
<stop stop-color="#333333"/>
<stop offset="0.1883"/>
<stop offset="0.3849" stop-color="#333333"/>
<stop offset="0.5983"/>
<stop offset="0.8033" stop-color="#333333"/>
<stop offset="1"/>
</linearGradient>
<radialGradient id="paint7_radial_6106_36860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.2692 17.1502) rotate(90.6464) scale(7.9108 19.4711)">
<stop stop-color="white" stop-opacity="0.8"/>
<stop offset="1" stop-color="white" stop-opacity="0.01"/>
</radialGradient>
</defs>
</svg>`
}