<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTables"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            :items="drivers"
            :fields="fields"
            v-hotkey="{ 'ctrl + f9': call }"
            @row-dblclicked="editForm"
            @row-contextmenu="handleContextMenuEvent"
            @row-clicked="clicked"
            head-row-variant="primary"
          >
            <template #cell(checkbox)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
              </b-form-checkbox>
            </template>
            <template #cell(first_name)="data">
              <span>
                {{
                  data.item.patronymic
                    ? data.item.last_name +
                      " " +
                      data.item.first_name +
                      " " +
                      data.item.patronymic
                    : data.item.last_name + " " + data.item.first_name
                }}
              </span>
            </template>
            <template #cell(gender)="data">
              <span v-if="data.item.gender == 1"> Мужской </span>
              <span v-else> Женский </span>
            </template>
            <template #cell(rating)="data">
              <span @dblclick="getratingInformation(data)" class="reference-field">
                {{ data.item.rating }}
              </span>
            </template>
            <template #cell(driver_cars)="data">
                  <span
                    @dblclick="getCar(data)"
                    class="reference-field"
                    select-variant="secondary"
                  >
                    {{ data.item.car_info }}
                  </span>
            </template>
            <template #cell(balance)="data" >
                <span class="reference-field" v-if="data.item.balance != null" @dblclick="getBalans(data)">
                  {{ data.item.balance }}
                </span>
            </template>
            <template #cell(report)="data">
              <span @dblclick="getMemos(data)" class="reference-field">
                (***)
              </span>
            </template>
            <!-- <template #cell(last_order_complated_date)="data">
                <span @dblclick="getPathData(data)" class="reference-field">
                  {{
                    data.item.last_order_complated_date
                  }}
                </span>
            </template> -->
            <template #cell(photo_control)="data">
              <span @dblclick="getPhotoControl(data)" class="reference-field">
                (***)
              </span>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                v-b-modal="$route.name + 'CreateModal'"
              >
                <feather-icon icon="PlusIcon" size="16" />
                <span class="ml-75">Добавить водителя</span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="editDriver()">
                <feather-icon icon="EditIcon" size="16" />
                <span class="ml-75">Изменить</span>
              </b-link>
              <b-link @click="call()" class="d-flex align-items-center">
                <feather-icon icon="PhoneOutgoingIcon" size="16" />
                <span class="ml-75">Позвонить</span>
              </b-link>
              <b-link class="d-flex align-items-center" v-b-modal.attachCar>
                <feather-icon icon="PaperclipIcon" size="16" />
                <span class="ml-75">Прикрепить автомобиль</span>
              </b-link>
              <b-link class="d-flex align-items-center" v-b-modal.messageCreateModalDriver>
                <feather-icon icon="MailIcon" size="16" />
                <span class="ml-75">Отправить смс</span>
              </b-link>
              <b-link class="d-flex align-items-center" @click="sendSmsPasswordReset()">
                <feather-icon icon="MailIcon" size="16" />
                <span class="ml-75">Отправить логин и пароль</span>
              </b-link>
            </li>
          </vue-context>
          <b-modal
            id="attachCar"
            title="Прикрепить автомобиль"
            size="sm"
            hide-footer no-enforce-focus
            :no-close-on-backdrop="true"
          >
            <div>
              <label for="">ID водителя</label>
              <b-input v-model="connect_car.driver_id"></b-input>
              <label for="" class="mt-1">Прикрепить автомобиль</label>
              <v-select
                v-model="connect_car.car_id"
                :options="cars"
                class="select-size-md mb-1"
                label="model"
                :reduce="(car) => car.id"
              >
                <template slot="option" slot-scope="option">
                  <div>
                    {{
                      option.car_brand +
                      " " +
                      option.model +
                      " " +
                      option.color +
                      "(" +
                      option.car_number +
                      ")"
                    }}
                  </div>
                </template>
                <template #selected-option="option">
                  <div style="display: flex; align-items: baseline">
                    <div>
                      {{
                        option.model + " " + option.color + "(" + option.car_number + ")"
                      }}
                    </div>
                  </div>
                </template>
              </v-select>
              <div style="display: flex; justify-content: flex-end">
                <b-button variant="primary" @click="connectCar()"> Прикрепить </b-button>
              </div>
            </div>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.css";
import VueContext from "vue-context";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { GlobalEventEmitter } from "@/utils/GlobalEventEmitter.js";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";

export default {
  props: ["drivers"],
  components: {
    VueContext,
    vSelect,
    ToastificationContent,
    GlobalEventEmitter,
  },
  data() {
    return {
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "30px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "40px" } },
        { key: "login", label: "Логин", sortable: true, thStyle: { width: "70px" } },
        { key: "balance", label: "Баланс", sortable: true, thStyle: { width: "70px" } },
        { key: "rating", label: "Рейтинг", sortable: true, thStyle: { width: "70px" } },
        { key: "created_by", label: "Создал", sortable: true, thStyle: { width: "127px" } },
        { key: "place_district",label: "Текущий район",sortable: true,thStyle: { width: "150px" },},
        { key: "division",label: "Подразделение",sortable: true,thStyle: { width: "150px" },},
        { key: "first_name",label: "Ф.И.О.",sortable: true,thStyle: { width: "250px" },},
        { key: "status", label: "Статус подключения", sortable: true, thStyle: { width: "70px" } },
        { key: "date_of_birth",label: "Дата рождения",sortable: true,thStyle: { width: "130px" },},
        { key: "phone", label: "Телефон", sortable: true, thStyle: { width: "116px" } },
        { key: "contact_number",label: "Доп. номер тел.",sortable: true,thStyle: { width: "150px" },},
        { key: "driver_cars",label: "Автомобили",sortable: true,thStyle: { width: "150px" },},
        { key: "serials_number",label: "Номер вод.удостоверения",sortable: true,thStyle: { width: "150px" },},
        { key: "expirated_driver_license",label: "Срок истечения вод. удостоверение",sortable: true,thStyle: { width: "150px" },},
        { key: "gender", label: "Пол", sortable: true, thStyle: { width: "150px" } },
        { key: "serial_number_passport",label: "Номер паспорта",sortable: true,thStyle: { width: "150px" },},
        { key: "expirated_passport",label: "Срок истечения паспорта",sortable: true,thStyle: { width: "150px" },},
        // { key: "passport_office",label: "Орган, выдающий документ",sortable: true,thStyle: { width: "150px" },},
        { key: "address", label: "Адрес", sortable: true, thStyle: { width: "150px" } },
        { key: 'report', label: "СЗ", sortable: true, thStyle: {width: '150px'} },
        { key: "district", label: "Район", sortable: true, thStyle: { width: "150px" } },
        { key: "created_at",label: "Дата регистрации",sortable: true,thStyle: { width: "150px" },},
        // { key: "last_order_complated_date",label: "Дата посл. заказа по подключению",sortable: true,thStyle: { width: "150px" },},
		    { key: "photo_control", label: "Фотоконтроль", sortable: true, thStyle: { width: "150px" },},
      ],
      id: "",
      connect_car: {
        car_id: "",
        driver_id: "",
      },
      cars: undefined,
      selectedRow: null,
      number: "",
      phone: null
    };
  },  
  mounted() {
    resizeable();
    this.$http.get("cars").then((res) => {
      this.cars = res.data;
    });
  },
  methods: {

    call() {
            if(this.$store.state.caller.status) {
                let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
                this.$http.post('/connect-driver-operator', {
                    number: initiatorNumber,
                    phone: this.phone
                })
                .then(() => {
                    this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Запрос на соединение отправлен',
                        icon: 'XIcon',
                        variant: 'success',
                        text: "",
                        },
                    })
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: "Ошибка",
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err,
                        },
                    })
                })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
    },
    getPathData(data){
        this.$store.commit('draggableTab/ADD_TAB',['История ' + data.item.id, `/taxi/driver/drivers-history-orders`, data.item.id  ])
        this.$router.push({name: 'drivers/drivers-history-orders', query:{driver_id: data.item.id}})
    },
    getMemos(data){
      this.$router.push({name: 'driversMemos', query:{filter: data.item.id}})
      this.$store.commit('draggableTab/ADD_TAB',['Служебные записки ' + data.item.id, `./taxi/driversMemos/index`, data.item.id  ])
    }, 
    getPhotoControl(data) {
      this.$router.push({name: 'photo-control/performer-assign/searchInfo', query:{filter: data.item.id}})
      this.$store.commit('draggableTab/ADD_TAB',['Фотоконтроль ' + data.item.id, `/taxi/photo-controlInfo`, data.item.id  ])
    },
    getratingInformation(data){
        this.$store.commit('draggableTab/ADD_TAB',['Рейтинг ' + data.item.id, `/taxi/rating/index`, data.item.id  ])
        this.$router.push({name: 'rating', query:{filter: data.item.id}})
    },
    sendSmsPasswordReset() {
      this.$http(`drivers/${this.id}/password-reset`)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CheckIcon",
              variant: "success",
              text: `Логин и пароль отправлены!`,
            },
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: "Произошла ошибка!",
            },
          });
        });
    },
    getBalans(data) {
      this.$router.push({name: 'balance', query:{driver_id: data.item.id}})
      this.$store.commit('draggableTab/ADD_TAB', ['Баланс ' +  data.item.id, `/driver/balance`, data.item.balance ])
    },
    getCar(data) {
      this.$router.push({ name: "carInfo", query: { filter: data.item.id } });
        this.$store.commit("draggableTab/ADD_TAB", [
          "Автомобиль " + data.item.id,
          `/driver/carInfo`,
          data.item.id,
        ]);
    },
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.phone = item.phone
      this.$refs.menu.open(event);
      this.id = item.id;
      this.connect_car.driver_id = item.id;
      this.$emit("sendPhoneNumber", item.phone);
      this.number = item.phone;
    },
    editForm(id) {
      this.$emit("editForm", id.id);
    },
    editDriver() {
      this.$emit("editForm", this.id);
    },
    connectCar() {
      this.$http
        .post(`drivers/${this.id}/connect`, this.connect_car)
        .then((res) => {
          this.$bvModal.hide("attachCar");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Успех!",
              icon: "CheckIcon",
              variant: "success",
              text: `Машина прикреплена!`,
            },
          });
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Некорректные данные!",
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.errors,
            },
          });
        });
    },
    clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }
            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.textContent).trim()
            cellSelect()
        },
  },
  filters: {
    formatDate(value) {
      return value.split(' ')[0];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-context.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
table {
  outline: none;
  table-layout: fixed;
  overflow: hidden;
  position: relative;
}
tr,
td,
th {
  padding: 0;
}
td,
th {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  min-width: 60px;
}
</style>
 