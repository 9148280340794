<template>
    <div>
        <b-modal
        id="modalSlegList"
        size="sm"
        centered
        hide-footer
        static
        no-enforce-focus
        :no-close-on-backdrop="true"
        hide-header-close
        >
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Выберите тип <i class="required">*</i></label>
                <v-select
                    label="slag"
                    :options="slegAll"
                    :reduce="option => option.slag"
                    @input="change"
                />
            </b-col>
        </b-row>
        </b-modal>
    </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
    },
    props: ['slegAll'],
    methods: {
        change($event) {
            this.$emit('change', $event)
        }
    }
    
}
</script>

<style lang="scss" scoped>
.required {
    color: red;
}
</style>