<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />     
        </div>
        <div v-else>
            <tableMessageTemplate :templates="templates" @editForm="editForm" @refresh="refresh"/>
        </div>
        <modalMessageTemplateCreate @refresh="refresh"/>
        <modalMessageTemplateEdit :id="id" @refresh="refresh"/>
    </div>
</template>

<script>
import tableMessageTemplate from '@/views/component/Table/tableMessageTemplate.vue'
import modalMessageTemplateCreate from '@/views/component/Modal/ModalMessageTemplate/modalMessageTemplateCreate.vue';
import modalMessageTemplateEdit from '@/views/component/Modal/ModalMessageTemplate/modalMessageTemplateEdit.vue'
export default {
    components: {
        tableMessageTemplate,
        modalMessageTemplateCreate,
        modalMessageTemplateEdit
    },
    data() {
        return {
            showPreloader: false,
            templates: [],
            id: null,
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            let params = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            this.showPreloader = true
            this.$http.get(`${this.$route.name}`, {params: params}).then(res => {
                this.templates = res.data
                this.showPreloader = false
                this.$store.commit('pageData/setdataCount', this.templates.length)
                this.$store.commit('REFRESH_DATA', false)
            })
        },
        editForm(id) {
            this.id = id
            setTimeout(() => {this.$bvModal.show('MessageTemplateEdit')}, 0);
        }
    },
    computed: {
        fetchingNewData() {
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData() {
            this.refresh()
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
