export default {
    namespaced: true,
    state: {
        is_free: true,
        circleRadius: 1,
        orderData: {},
        performerData: {},
        is_activePerformer: true,
        is_activeOrder: true,
        dataOrder: [],
    },
    mutations: {
        SETISFREE(state, payload) {
            state.is_free = payload
        },
        SETCIRCLE_RADIUS(state, payload) {
            state.circleRadius = payload
        },
        ORDERDATA(state, payload) {
            state.orderData = payload
        },
        PERFORMERDATA(state, payload) {
            state.performerData = payload
        },
        SETISACTIVEPERFORMER(state, payload) {
            state.is_activePerformer = payload
        },
        SETISACTIVEORDER(state, payload) {
            state.is_activeOrder = payload
        },
        SETDATAORDER(state, payload) {
            state.dataOrder = payload
        },
        SETDATAPUSH(state, payload) {
            state.dataOrder.push(payload)
        }   
    },
    actions: {

    },
    getters: {

    }
}