<template>
    <div>
        <b-modal
        :id="$route.name + 'CreateModal'"
        title="Создание слага"
        size="md"
        hide-footer
        no-enforce-focus
        :no-close-on-backdrop="true"
        @close="close"
        >
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Название слага (латиница) <i class="required" >*</i></label>
                <b-input :class="valid && slag ? 'custom-red-border' : ''" v-model.trim="slag" placeholder="Название слага" />
            </b-col>
        </b-row>
        <b-row class="mb-1 mt-2">
            <b-col class="d-flex justify-content-between">
                <b-button @click="close"  variant="danger">Отмена</b-button>
                <b-button @click="create()" :disabled="submitButtonDisabled"  variant="primary">Добавить</b-button>
            </b-col>
        </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    data() {
        return {
            slag: '',
            submitButtonDisabled: false,
            patterns: /^[A-Za-z-]+$/,
        }
    },
    methods: {
        closeData() {
            this.slag = ''
        },
        close() {
            this.closeData()
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
        create() {
            if(this.slag) {
                this.submitButtonDisabled = true
                this.$http.post(`slags`, { slag: this.slag })
                .then((res) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Слаг cоздана!',
                            icon: 'XIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    });
                    this.close()
                    this.$emit('refresh')
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    });
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
            }
        },
    },
    computed: {
        valid() {
            return !this.patterns.test(this.slag)
        }
    }
}
</script>

<style lang="scss" scoped>
.required {
    color: red;
}
.custom-red-border {
  border: 1px solid red;
}
</style>