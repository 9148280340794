<template>
    <div>
        <b-modal
        id="editPoiCategory"
        title="Изменить данные категории"
        size="sm"
        hide-footer
        no-enforce-focus
        :no-close-on-backdrop="true"
        @show="getPoiCategory"
        @close="close"
        >
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Название категории <i class="required" >*</i></label>
                <b-input v-model="poiCategory.name" placeholder="Название категории"/>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Название кода (латиница) <i class="required" >*</i></label>
                <b-input :class="valid && poiCategory.code ? 'custom-red-border' : ''" v-model.trim="poiCategory.code" placeholder="Название категории" />
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                <img class="mw-100" v-if="poiCategory.icon" :src="poiCategory.icon" alt="" srcset="">
                <label>Иконка (для приложения)</label>
                    <b-form-file browse-text="Добавить" @change="uploadFile($event, 'icon')" placeholder="Выберите файл или перетащите его сюда...">
                </b-form-file>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                <img class="mw-100" v-if="poiCategory.iconByMap" :src="poiCategory.iconByMap" alt="" srcset="">
                <label>Иконка (для карты)</label>
                    <b-form-file browse-text="Добавить" @change="uploadFile($event, 'iconByMap')" placeholder="Выберите файл или перетащите его сюда...">
                </b-form-file>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col class="status">
                <label for="">Cтатус</label>
                <b-form-checkbox v-model="poiCategory.isActive" checked="true" name="check-button" switch inline>
                    {{ isActive }}
                </b-form-checkbox>
            </b-col>
        </b-row>
        <b-row class="mb-1 mt-2">
            <b-col class="d-flex justify-content-between">
                <b-button @click="close()"  variant="danger">Отмена</b-button>
                <b-button @click="request()" :disabled="submitButtonDisabled"  variant="primary">Изменить</b-button>
            </b-col>
        </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent
    },
    props: ['id'],
    data() {
        return {
            poiCategory: {
                name: null,
                code: null,
                isActive: null,
                icon: null,
                iconByMap: null
            },
            submitButtonDisabled: false,
            patterns: /^[A-Za-z_]+$/,
            iconFile: null,
            iconByMapFile: null,
        }
    },
    methods: {
        getPoiCategory() {
            this.$http.get(`poicategories/${this.id}`)
            .then((res) => {
                this.poiCategory = res.data
                this.poiCategory.isActive = this.poiCategory.isActive == 1 ? true : false
            })
            .catch((error) => {
                console.log(error);
            })
        },
        close() {
            this.$bvModal.hide('editPoiCategory')
            this.clearData()
        },
        uploadFile(event, param) {
            if(param == 'icon') {
                this.iconFile = event.target.files[0];
                this.poiCategory.icon = URL.createObjectURL(this.iconFile);
            }else {
                this.iconByMapFile = event.target.files[0];
                this.poiCategory.iconByMap = URL.createObjectURL(this.iconByMapFile);
            }
        },
        clearData() {
            this.poiCategory = {
                name: null,
                code: null,
                isActive: null,
                icon: null,
                iconByMap: null
            },
            this.iconFile = null,
            this.iconByMapFile = null
        },
        async request() {
            let pathRequest = `poicategories/${this.poiCategory.id}/${this.poiCategory.isActive === true ? 'unlock' : 'block'}`
            await this.block(pathRequest)
            if(this.poiCategory.icon && this.iconFile) {
                await this.setIcon()
            }
            if(this.poiCategory.iconByMap && this.iconByMapFile) {
                await this.setIconByMap()
            }
            await this.edit()
        },
        setIconByMap() {
            const formData = new FormData();
            formData.append("icon", this.iconByMapFile);
            this.$http.post(`poicategories/${this.poiCategory.id}/setIconByMap`, formData)
            .then((res) => {
                this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: `Фото успешно изменено!`,
						icon: 'XIcon',
						variant: 'success',
						text: res.data.name,
					},
				});
                formData.delete("icon");
            })
            .catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: error.response.data.errors,
                    },
                });
            })
        },
        setIcon() {
            const formData = new FormData();
            formData.append("icon", this.iconFile);
            this.$http.post(`poicategories/${this.poiCategory.id}/setIcon`, formData)
            .then((res) => {
                this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: `Фото успешно изменено!`,
						icon: 'XIcon',
						variant: 'success',
						text: res.data.name,
					},
				});
                formData.delete("icon");
            })
            .catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: error.response.data.errors,
                    },
                });
            })
        },
        block(param) {
            this.$http.put(param)
            .then((res) => {
                this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: `${this.isActive}!`,
						icon: 'XIcon',
						variant: 'success',
						text: res.data.name,
					},
				});
            })
            .catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: error.response.data.errors,
                    },
                });
            })
        },
        edit() {
            this.submitButtonDisabled = true
            this.$http.patch(`poicategories/${this.poiCategory.id}`, this.poiCategory)
            .then((res) => {
                this.close()
                this.$emit('refresh')
                this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Категория изменено!',
						icon: 'XIcon',
						variant: 'success',
						text: res.data.name,
					},
				});
            })
            .catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: error.response.data.errors,
                    },
                });
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        }
    },
    computed: {
        valid() {
            return !this.patterns.test(this.poiCategory.code)
        },
        isActive() {
            return this.poiCategory.isActive ? 'Не блокирован' : 'Блокирован'
        }
    }
}
</script>

<style lang="scss" scoped>
.required {
    color: red;
}
.custom-red-border {
  border: 1px solid red;
}
.status {
    display: flex;
    flex-direction: column;
}
</style>