<template>
    <div class="mapModalOrder  border rounded" style="width: 600px;">
        <p class="ml-1">Подразделение:</p>
        <div class="d-flex">
            <v-select class="ml-1 w-50" placeholder="Подразделение" label="name" :reduce="options => options"
                :options="orderMap.divisions" v-model="division_id" @input="getOpenMap($event)">
                <template #no-options>
                    <span>
                        Данные отсутствуют!
                    </span>
                </template>
            </v-select>
            <b-button style="height: 40px; padding: 3px; margin-left: 20px;" variant="primary" @click="update()">
                Обновить карту
            </b-button>
            <b-button style="margin-left: 20px; height: 40px;" variant="primary">?</b-button>
        </div>
        <b-col class="mt-2">
            <b-form-checkbox v-model="isActivePerformen" @input="getActivePerformer($event)">Скрыть водителей на
                заказе</b-form-checkbox>
        </b-col>
        <hr class="mt-2">
        <b-col class="w-50">
            <p>Радиус заказа (км):</p>
            <b-form-input type="number" :disabled="isRadiusActive" v-model.number="isRadius"
                @keyup.enter="getRadiusId"></b-form-input>
        </b-col>
        
        <OrderInfo :isActiveOrder="isActiveOrder" :orderDirvers="orderDirvers" :order="order"/>

        <div class="d-flex justify-content-between m-1" v-if="isActiveOrder">
            <b-button variant="primary" class="button_call" @click="getCall(orderDirvers.phone)">Позвонить клиенту</b-button>
            <b-button :disabled="isOrderActivePerformen" variant="primary" class="button_assign">Отменить заказ</b-button>
        </div>
        
        <DriverInfo :isActive="isActive" :performers="performers" :performerExecutor="performerExecutor"/>

        <div v-if="is_free === 1">
            <div class="d-flex justify-content-between m-1" v-if="isActive">
                <b-button variant="primary" class="button_call" @click="getCall(performerExecutor.phone)">Позвонить водителю</b-button>
                <b-button variant="primary" class="button_assign" :disabled="isAssignButtonDisabled"
                    @click="assignDriver()">Назначить водителя</b-button>
            </div>
        </div>
        <div class="order border border-black rounded">
            <h4>Маршрут до заказа</h4>
            <div v-if="isActiveOrder">
                <p>Расстояние(По городу):</p>
                <p>{{ orderDirvers.free_km }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import OrderInfo from './OrderInfo.vue';
import DriverInfo from './DriverInfo.vue';
export default {
    components: {
        vSelect,
        ToastificationContent,
        OrderInfo,
        DriverInfo
    },
    props: ['orderMap', 'order', 'performers', 'division'],
    data() {
        return {
            division_id: this.division,
            isActive: false,
            isActiveOrder: false,
            isActivePerformen: true,
            isOrderActivePerformen: true,
            isDisabledOrder: true,
            isDisabledPerformer: true,
            isRadius: 1500,
            isRadiusActive: true,
            is_free: '',
            phone: '',
            orderDirvers: [],
            performerExecutor: [],
            destination: {
                performer_id: this.performers,
                order_id: this.order,
            },
        }
    },
    computed: {
        isAssignButtonDisabled() {
            return this.isDisabledOrder || this.isDisabledPerformer;
        },
    },
    watch: {
        order(newValue) {
            if(newValue == '') {
                this.isActiveOrder = false
                this.isRadiusActive = true
            }else {
                this.isActiveOrder = false
                this.isRadiusActive = true
                this.destination.order_id = newValue.id
                this.$http
                    .get(`orders-map/orders/${newValue.id}/show`)
                    .then(res => {
                        this.getRadiusId()
                        this.orderDirvers = res.data
                        this.isDisabledOrder = false
                        this.isActiveOrder = true
                        this.isRadiusActive = false
                    })
            }
        },
        performers(newValue) {
            if(newValue == '') {
                this.isActive = false
            }else {
                this.isActive = false
                this.destination.performer_id = newValue.id
                this.$http
                    .get(`orders-map/performers/${newValue.id}/show`)
                    .then(res => {
                        this.performerExecutor = res.data
                        this.is_free = newValue.free
                        this.isDisabledPerformer = false
                        this.isActive = true
                    })
            }
        },
    },
    methods: {
        assignDriver() {
            if (this.order && this.performers) {
                this.isDisabledOrder = true
                this.$http
                    .post("orders-map/orders/order-destination", this.destination)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Успех!',
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                        })
                        this.update()
                        this.getClear()
                        this.isActiveOrder = false
                        this.isActive = false
                        this.isDisabledOrder = false
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-rigth',
                            props: {
                                title: 'Некорректные данные!',
                                icon: 'XIcon',
                                variant: 'danger',
                                text: err.message,
                            }
                        })
                    }).finally(() => {
                        this.isDisabledOrder = false;
                    });
            }else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-rigth',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: 'Выберите заказ или водителя!',
                    }
                })
            }
        },
        getActivePerformer($event) {
            this.isActivePerformen = $event
            this.$emit('getIsActiveFree', $event)
        },
        getOpenMap($event) {
            let division = $event
            this.isActiveOrder = false
            this.isActive = false
            this.$emit('getOpenMap', division)
        },
        getRadiusId() {
            this.$emit('getRadiusId', this.isRadius)
        },
        update() {
            this.$emit('getOpenMap')
            this.$root.$emit('callClearClickInOtherComponent');
            this.isRadiusActive = true
        },
        getCall(phone) {
            if (this.$store.state.caller.status) {
                let initiatorNumber = JSON.parse(localStorage.getItem('sipConfig')).user
                this.$http.post('/connect-driver-operator', {
                    number: initiatorNumber,
                    phone: phone,
                })
                    .then(() => {
                        this.$store.commit('caller/UPDATE_IS_INITIATOR_STATE', true)
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Запрос на соединение отправлен',
                                icon: 'XIcon',
                                variant: 'success',
                                text: "",
                            },
                        })
                    })
                    .catch((err) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: "Ошибка",
                                icon: 'XIcon',
                                variant: 'danger',
                                text: err,
                            },
                        })
                    })
            }
            else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Включите SIP телефонию',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: "",
                    },
                })
            }
        },
        getClear() {
            this.isActive = false,
            this.isActiveOrder = false,
            this.isActivePerformen = true,
            this.isOrderActivePerformen = true,
            this.isDisabledOrder = true,
            this.isDisabledPerformer = true,
            this.isRadiusActive = true
            this.$store.commit('map/SETDATAORDER', [])
        }
    },
    created() {
        this.$root.$on('isRadiusActiveTrue', (is_active) => {
            this.isRadiusActive = is_active
        })
    }
}
</script>

<style scoped>
.mapModalOrder {
    width: 100%;
    height: calc(100vh - 93px)  !important;
    overflow: auto;
}

.order {
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 10px;
}

.button_call {
    width: 200px;
    height: 40px;
}

.button_assign {
    width: 200px;
    height: 40px;
}
</style>