<template>
    <div>
        <b-modal
        id="editVersionControl"
        title="Изменить данные версии"
        size="sm"
        hide-footer
        no-enforce-focus
        :no-close-on-backdrop="true"
        @show="getVersionControl"
        @close="close"
        >
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Корректное версия <i class="required" >*</i></label>
                <b-input v-mask="'#.#.#'"  v-model="versionControl.current_version" placeholder="Название версии" />
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Минимальное версия <i class="required" >*</i></label>
                <b-input v-mask="'#.#.#'" v-model="versionControl.min_version" placeholder="Название версии" />
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Стабильная версия <i class="required" >*</i></label>
                <b-input v-mask="'#.#.#'" v-model="versionControl.stable_version"  placeholder="Название версии" />
            </b-col>
        </b-row>
        <b-row class="mb-1" >
            <b-col cols="12">
                <label for="">Шаблон сообщения <i class="required" >*</i></label>
                <v-select
                    label="title"
                    :options="templates"
                    v-model="versionControl.message_template_id"
                    :reduce="option => option.id"
                />
            </b-col>
        </b-row>
        <b-row class="mb-1" >
            <b-col cols="12">
                <label for="">Сервис <i class="required" >*</i></label>
                <v-select
                    label="trslate_name"
                    :options="services"
                    v-model="versionControl.services"
                    :reduce="option => option.name"
                />
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Платформа <i class="required" >*</i></label>
                <v-select
                    label="trslate_name"
                    :options="platform"
                    v-model="versionControl.platform"
                    :reduce="option => option.name"
                />
            </b-col>
        </b-row>
        <b-row class="mb-1 mt-2">
            <b-col class="d-flex justify-content-between">
                <b-button @click="close()"  variant="danger">Отмена</b-button>
                <b-button @click="edit()" :disabled="submitButtonDisabled"  variant="primary">Изменить</b-button>
            </b-col>
        </b-row>
        </b-modal>
    </div>
</template>

<script>
import { mask } from "vue-the-mask"
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    directives: { mask },
    components: {
        vSelect,
    },
    props: ['id', 'templates', 'services', 'platform'],
    data() {
        return {
            versionControl: {
                current_version: null,
                min_version: null,
                stable_version: null,
                services: null,
                platform: null,
                message_template_id: null
            },
            submitButtonDisabled: false
        }
    },
    methods: {  
        getVersionControl() {
            this.versionControl = this.id
        },
        clearData() {
            this.versionControl = {
                current_version: null,
                min_version: null,
                stable_version: null,
                services: null,
                platform: null,
                message_template_id: null
            }
        },
        edit() {
            this.submitButtonDisabled = true
            this.$http.patch(`app-versions/${this.versionControl.id}`, this.versionControl)
            .then((res) => {
                this.$toast({
					component: ToastificationContent,
					position: 'top-right',
					props: {
						title: 'Версия изменено!',
						icon: 'XIcon',
						variant: 'success',
						text: res.data.message,
					},
				});
                this.close()
                this.$emit('refresh')
            })
            .catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: error.response.data.errors,
                    },
                });
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        close() {
            this.clearData()
            this.$bvModal.hide('editVersionControl')
        }
    }
}
</script>

<style lang="scss" scoped>
.required {
    color: red;
}
</style>