<template>
    <div>
        <b-modal
        :id="$route.name + 'CreateModal'"
        title="Создание категории"
        size="md"
        hide-footer
        no-enforce-focus
        :no-close-on-backdrop="true"
        @close="close"
        >
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Название категории <i class="required" >*</i></label>
                <b-input v-model="poiCategory.name" placeholder="Название категории"/>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col cols="12">
                <label for="">Название кода (латиница) <i class="required" >*</i></label>
                <b-input :class="valid && poiCategory.code ? 'custom-red-border' : ''" v-model.trim="poiCategory.code" placeholder="Название категории" />
            </b-col>
        </b-row>
        <b-row class="mb-1 mt-2">
            <b-col class="d-flex justify-content-between">
                <b-button @click="close"  variant="danger">Отмена</b-button>
                <b-button @click="create()" :disabled="submitButtonDisabled"  variant="primary">Добавить</b-button>
            </b-col>
        </b-row>
        </b-modal>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        ToastificationContent,
    },
    data() {
        return {
            poiCategory: {
                name: null,
                code: null,
            },
            submitButtonDisabled: false,
            patterns: /^[A-Za-z_]+$/,
        }
    },
    methods: {
        create() {
            if(this.poiCategory.name && this.poiCategory.code && !this.valid) {
                this.submitButtonDisabled = true
                this.$http.post('poicategories', this.poiCategory)
                .then((res) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Категория cоздана!',
                            icon: 'XIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    });
                    this.close()
                    this.$emit('refresh')
                })
                .catch((err) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Некорректные данные!',
                            icon: 'XIcon',
                            variant: 'danger',
                            text: err.response.data.errors,
                        },
                    });
                })
                .finally(() => {
                    this.submitButtonDisabled = false
                })
                
            }
        },
        close() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData() {
            this.poiCategory = {
                name: null,
                code: null,
            }
        },
    },
    computed: {
        valid() {
            return !this.patterns.test(this.poiCategory.code)
        }
    }
}
</script>

<style lang="scss" scoped>
.required {
    color: red;
}
.custom-red-border {
  border: 1px solid red;
}
</style>