<template>
  <div class="order border border-black rounded">
        <h4>Информамация о заказе</h4>
        <div v-if="isActiveOrder">
            <p @click="getOrder()" class="orderColor">ID: {{ orderDirvers.id }}</p>
            <p>Тариф: {{ orderDirvers.tariff }}</p>
            <p>Статус: {{ orderDirvers.status }}</p>
            <p>Цена водителя: {{ orderDirvers.price }}</p>
            <p>ВП: {{ orderDirvers.past_minute }}</p>
            <p>Расстояние: {{ orderDirvers.distance }}</p>
            <p>Расстояние(По городу): {{ orderDirvers.distance_in_city }}</p>
            <p>Расстояние(Межгород): {{ orderDirvers.search_address_id }}</p>
            <p>Холостой: {{ orderDirvers.free_km }}</p>
            <p>Маршрут:</p>
            <p>><span v-if="orderDirvers.from_address">
                <span
                    v-if="orderDirvers.from_address.type === 'fast_address' || orderDirvers.from_address.type === 'address_point'">
                    {{ orderDirvers.from_address.name }}
                </span>
                <span v-if="orderDirvers.from_address.type === 'address'">
                    <span v-if="orderDirvers.from_address.street_type == 'микрорайон'">
                        <span>
                            {{ orderDirvers.from_address.street + ' ' + orderDirvers.from_address.street_type + ', ' + orderDirvers.from_address.name }}
                        </span>
                    </span>
                    <span v-else>
                        <span>
                            {{ orderDirvers.from_address.street_type + ' ' + orderDirvers.from_address.street + ', ' + orderDirvers.from_address.name }}
                        </span>
                    </span>
                </span>
            </span>{{ orderDirvers.meeting_info ? `(${orderDirvers.meeting_info})` : '' }}</p>
            <p>> <span v-for="(to_address, idx) in orderDirvers.to_addresses" :key="idx">
                    <span v-if="to_address">
                        <span v-if="to_address.type === 'fast_address' || to_address.type === 'address_point'">
                            {{ to_address.name + ';' }}
                        </span>
                        <span v-if="to_address.type === 'address'">
                            <span v-if="to_address.street_type == 'микрорайон'">
                                <span>
                                    {{ to_address.street + ' ' + to_address.street_type + ', ' + to_address.name + ';' }}
                                </span>
                            </span>
                            <span v-else>
                                <span>
                                    {{ to_address.street_type + ' ' + to_address.street + ', ' + to_address.name + ';' }}
                                </span>
                            </span>
                        </span>
                    </span>
                </span></p>
            <p>{{ `${orderDirvers.info_for_operator ? orderDirvers.info_for_operator : ''} ${orderDirvers.info_for_drivers ? orderDirvers.info_for_drivers : ''} ` }}</p>
        </div>
    </div>
</template>

<script>
export default {
  props:['isActiveOrder', 'orderDirvers', 'order'],
  methods: {
    getOrder() {
        this.$router.push({ name: 'infoOrderMap', query: { order: this.order.id } })
        this.$store.commit('draggableTab/ADD_TAB', ['Закази ' + this.order.id, `/infoOrderMap`, this.order.id])
    },
  }
};
</script>

<style scoped>
.orderColor {
    color: blue;
    font-size: 15px;
    cursor: pointer;
}

.order {
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 10px;
}
</style>