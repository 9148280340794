import io from 'socket.io-client';
import { baseURLSocket } from '@/libs/constant';

const token = localStorage.getItem('accessToken')
const socket = io(baseURLSocket, {
  transports: ["websocket"],
  forceNew: true,
  auth: {
    token: token 
  }
});

export default socket