<template>
    <b-row>
        <b-col cols="2">
            <p>{{ field.label }}</p>
        </b-col>
        <b-col class="mb-1">
            <v-select
                    label="name"
                    :options="city"
                    v-model="cityCode"
                    :reduce="option => option.code"
                    @input="updateModel($event, 'cityCode')"
                />
        </b-col>
    </b-row>
</template>

<script>
import vSelect from 'vue-select'
export default {
    components: {
        vSelect
    },
    props: ['value', 'field'],
    name: 'cityCode',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            city: [],
            cityCode: localStorage.getItem("city") ? localStorage.getItem("city") : null,
        }
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'cityCode' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'cityCode';
            this.updateModel(value, name);
        }
        this.getCity()
    },
    methods: {
        getCity() {
            this.$http.get('admin/city')
            .then((res) => {
                this.city = res.data
            })
        },
        updateModel(value, name) {
            localStorage.removeItem('city')
            localStorage.setItem('city', value)
            this.$emit('change', { val: value, field: name })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>