import * as turf from '@turf/turf';

var options = {
    steps: 64,
    units: 'meters'
}

export const createCircleOrder = (e, radius, map) => {
    let circleOrder = [];

    circleOrder = turf.circle([e.lng, e.lat], radius, options)
    map.addLayer({
        id: "location-radius",
        type: "fill",
        source: {
          type: "geojson",
          data: circleOrder,
        },
        paint: {
          "fill-color": "#8CCFFF",
          "fill-opacity": 0.5,
        },
    });
    map.addLayer({
        id: "location-radius-outline",
        type: "line",
        source: {
          type: "geojson",
          data: circleOrder,
        },
        paint: {
          "line-color": "#0094ff",
          "line-width": 3,
        },
    });

    return circleOrder
}