<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-driver :drivers="drivers" @editForm="editForm" @refresh="refresh" @sendPhoneNumber="assignPhoneNumber" />
            <modal-driver @clearCarId="clearCarId" :car_info="car_info" @refresh="refresh" :carId="carId"/>
            <modal-driver-edit :driver_id="driver_id" :car_info="car_info" @refresh="refresh" :carId="carId"/>
            <modal-driver-car @sendCarInfo="sendCarInfo" @connectCar="connectCar"></modal-driver-car>
            <mail-modal :phoneNumber="phoneNumber"/>
            <modal-push></modal-push>
            <ModalForDriverMessage :perfomerPhone="phoneNumber"></ModalForDriverMessage>
            <!-- <modal-balance @refresh="refresh"></modal-balance>
            <modal-charge @refresh="refresh"></modal-charge> -->
            <!-- <modal-driver-filter @filteredDrivers="filteredDrivers"></modal-driver-filter> -->
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableDriver from "@/views/component/Table/tableDriver.vue"
import modalDriver from "@/views/component/Modal/ModalDriver/modalDriver.vue"
import modalDriverEdit from "@/views/component/Modal/ModalDriver/modalDriverEdit.vue"
import modalDriverCar from "@/views/component/Modal/ModalDriver/modalDriverCar.vue"
import MailModal from "@/views/component/Modal/ModalOrder/MailModal.vue"
import modalPush from "@/views/component/Modal/ModalDriver/modalPush.vue"
import ModalForDriverMessage from "@/views/component/Modal/ModalForDriverMessage/MessageForDriver.vue"
// import modalBalance from "@/views/component/Modal/ModalDriver/modalBalance.vue"
// import modalCharge from "@/views/component/Modal/ModalDriver/modalCharge.vue"
// import modalDriverFilter from "@/views/component/Modal/ModalDriver/modalDriverFilter.vue"

export default {
    components: {
        tableDriver,
        modalDriver,
        modalDriverEdit,
        modalDriverCar,
        MailModal,
        modalPush,
        ModalForDriverMessage
        // modalBalance,
        // modalCharge,
        // modalDriverFilter
    },
    data(){
        return {
            driver_id: '',
            id: '',
            drivers: [],
            showPreloader: false,
            test: true,
            car_info: undefined,
            perfomerPhone: null,
            carId: undefined,
            fields: [
                { key: 'checkbox', label: ''},
                { key: 'id', label: 'Id'},
                { key: 'login', label: 'Логин'},
                { key: 'division', label: 'Подразделение'},
                { key: 'full_name', label: 'Ф.И.О' },
                { key: 'phone', label: 'Телефон' },
                { key: 'serials_number', label: 'Номер вод.удостоверения' },
                { key: 'car_number', label: 'Номер машины' },
                { key: 'gender', label: 'Пол' }, 
                { key: 'serial_number_passport', label: 'Номер паспорта' },
                // { key: 'address', label: 'Адрес' },
                { key: 'district', label: 'Район' },
                { key: 'car_info', label: 'Текуший автомобил' },
                { key: 'created_by', label: 'Создал' },
                { key: 'status_auto', label: 'Статус' },
                { key: 'is_block', label: 'Состояния' },
                { key: 'date_time', label: 'Регистрирован' },
                { key: 'date_of_birth', label: 'Дата рождения' }
            ],
            phoneNumber: null,
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        sendToParent(tableData){
            this.drivers = tableData
            this.$store.commit('pageData/setdataCount', this.drivers.length)
        },
        editForm(id){
            this.driver_id = id
            setTimeout(this.openModal, 0);
        },
        clearCarId(item){
            this.carId = item
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if(this.perfomerPhone) {
                param.filter_phone = this.perfomerPhone;
                console.log(this.perfomerPhone, 'vfvfvfvf');
            }
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.drivers = res.data
                        this.$store.commit('pageData/setdataCount', this.drivers.length)

                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
            this.$store.state.filterPlus.filterValue = null
        },
        openModal(){
            this.$bvModal.show('modalDriverEdit')
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('drivers').then(res => {
                    this.drivers = res.data
                    this.$store.commit('pageData/setdataCount', this.drivers.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },
        connectCar(car_id){
            this.carId = car_id
        },
        sendCarInfo(car_info){
            this.car_info = car_info
        },
        assignPhoneNumber(phoneNumber) {
            this.phoneNumber = phoneNumber;
        }
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        '$store.state.filterPlus.filterValue': {
            immediate: true,
            handler(newFilterValue) {
                if (newFilterValue) {
                    this.perfomerPhone = '992' + newFilterValue
                    this.openFilter(); 
                }
            },
        }, 
        fetchingNewData(val){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if (val){
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res =>{
                    this.drivers = res.data
                    this.$store.commit('pageData/setdataCount', this.drivers.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    },
}
</script>
