<template>
<div class="order-list">
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh; z-index: 10; position: absolute; left: 50%;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
            <div class="parent_block">
                <b-row class="block1" style="height: calc(100vh - 92px);">
                    <b-col cols="12">
                            <b-table 
                            striped
                            small
                            id="myTable"
                            bordered
                            selectable
                            selected-variant="primary"
                            select-mode="single"
                            responsive="sm"
                            :items="orders"
                            :fields="field"
                            head-row-variant="secondary"
                            :tbody-tr-class="rowClass"
                            @row-dblclicked="selectOrder"
                            @row-clicked="clicked"
                            v-hotkey="{ 'F2': openHandle, 'F11': openHistoryModal}"
                            @row-contextmenu="handleContextMenuEvent"
                            @row-hovered="hovering"
                            @row-unhovered="unhovering"
                        >
                            <template #cell(checkbox)="row">
                                <b-form-checkbox
                                v-model="row.detailsShowing"
                                plain
                                class="vs-checkbox-con"
                                @change="row.toggleDetails"
                                >
                                </b-form-checkbox>
                            </template>
                            <template #cell(create_user)="data">
                                {{data.item.create_user ? data.item.create_user.login : ''}}3
                            </template>
                            <template #cell(to_addresses)="data">
                                <span v-for="(to_address, idx) in data.item.to_addresses" :key="idx">
                                    <span v-if="to_address">
                                        <span v-if="to_address.type === 'fast_address' || to_address.type === 'address_point'">
                                            {{ to_address.name + ';' }}
                                        </span>
                                        <span v-if="to_address.type === 'address'">
                                            <span v-if="to_address.street_type == 'микрорайон'">
                                                <span>
                                                {{ to_address.street + ' ' + to_address.street_type + ', ' + to_address.name + ';' }}
                                                </span>
                                            </span>
                                            <span v-else>
                                                <span>
                                                {{ to_address.street_type + ' ' + to_address.street + ', ' + to_address.name + ';' }}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </template>
                            <template #cell(from_address)="data">
                                <span v-if="data.item.from_address">
                                    <span v-if="data.item.from_address.type === 'fast_address' || data.item.from_address.type === 'address_point'">
                                        {{ data.item.from_address.name }}
                                    </span>
                                    <span v-if="data.item.from_address.type === 'address'">
                                        <span v-if="data.item.from_address.street_type == 'микрорайон'">
                                            <span>
                                                {{ data.item.from_address.street + ' ' + data.item.from_address.street_type + ', ' + data.item.from_address.name }}
                                            </span>
                                        </span>
                                        <span v-else>
                                            <span>
                                                {{ data.item.from_address.street_type + ' ' + data.item.from_address.street + ', ' + data.item.from_address.name }}
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </template>
                            <template #cell(performer_name)="data">
                                {{data.item.performer==null ? '' :data.item.performer.last_name+' '+data.item.performer.first_name}}
                            </template>
                            <template #cell(performer_phone)="data">
                                {{ data.item.performer ? data.item.performer.phone : '' }}
                            </template>
                            <template #cell(performer_login)="data">
                                <span @dblclick="getLogin(data)" v-if="data.item.performer" class="reference-field">
                                    {{ data.item.performer ? data.item.performer.login : '' }}
                                </span>
                            </template>
                            <template #cell(performer_transport)="data">
                                {{data.item.performer==null || data.item.performer.transport==null? '' : data.item.performer.transport.model + ` ${data.item.performer.transport.color}` + ` ${data.item.performer.transport.car_number}`}}
                            </template>
                            <template #cell(distance)="data">
                                {{data.item.distance!=null ? data.item.distance.toFixed(1)+'км' : ''}}
                            </template>
                            <template #cell(allowances)="data">
                                <span v-for="allowance in data.item.allowances" :key="allowance.id">
                                    {{ allowance.name + '; ' }}
                                </span>
                            </template>
                            <template #cell(report)="data">
                                <span @dblclick="getAssigment(data)" class="reference-field">
                                    (***)
                                </span>
                            </template>  
                            <template #cell(history)="data">
                                <span @dblclick="getPathData(data)" class="reference-field">
                                    (***)
                                </span>
                            </template>
                            <template #cell(history_call)="data">
                                <span @dblclick="getHistoryCall(data)" class="reference-field">
                                    (***)
                                </span>
                            </template>
                        </b-table>
                        <vue-context ref="menu">
                            <li>
                                <b-link
                                class="d-flex align-items-center"
                                @click="getOrderByIds()"
                                v-b-modal.handle_order
                                >
                                <feather-icon
                                    icon="EditIcon"
                                    size="16"
                                />
                                <span class="ml-75">Обработать</span>
                                </b-link>
                                <b-link
                                class="d-flex align-items-center"
                                @click="showOnTheMap()"
                                v-hotkey="{ 'ctrl+m': showOnTheMap }"

                                >
                                <feather-icon
                                    icon="NavigationIcon"
                                    size="16"
                                />
                                <span class="ml-75">Открыть на карте</span>
                                </b-link>
                                <b-link
                                class="d-flex align-items-center"
                                v-b-modal.messageCreateModal
                                >
                                <feather-icon icon="MailIcon" />
                                <span class="ml-75">Отправить СМС</span>
                                </b-link>
                                <b-link
                                class="d-flex align-items-center"
                                v-b-modal.messageCreateModalDriver
                                >
                                <feather-icon icon="MailIcon" />
                                <span class="ml-75">Отправить СМС водителю </span>
                                </b-link>
                                <b-link
                                class="d-flex align-items-center"
                                @click="getOrderById()"
                                v-b-modal.edit_order
                                >
                                <feather-icon
                                    icon="Edit2Icon"
                                    size="16"
                                />
                                <span class="ml-75">Изменить</span>
                                </b-link>
                                <b-link
                                class="d-flex align-items-center"
                                @click="getOrderById()"
                                v-b-modal.show_order
                                >
                                <feather-icon
                                    icon="EyeIcon"
                                    size="16"
                                />        
                                <span class="ml-75">Просмотр</span>
                                </b-link>
                                <b-link
                                v-if="positionId == 1"
                                class="d-flex align-items-center"
                                v-b-modal.order_history
                                >
                                <feather-icon
                                    icon="BookOpenIcon"
                                    size="16"
                                />
                                <span class="ml-75">История изменения</span>
                                </b-link>
                                <div v-else style="padding: 0.65rem 1.28rem; cursor: pointer;" class="d-flex align-items-center" @click="getPath()">
                                    
                                    <feather-icon
                                        icon="BookOpenIcon"
                                        size="16"
                                    />
                                    <span class="ml-75">История изменения</span>
                                </div>
                            </li>
                        </vue-context>
                    </b-col>
                </b-row>
            </div>
        <modal-order-create :orderData="orderData" @refresh="refresh" v-hotkey="{'insert': showCreateOrder}" @GetOrder="getOrder"/>
        <modal-order-handle :orderData="orderData" @refresh="refresh" :editOrder="editOrder" :orderId="orderId"/>
        <modal-order-edit :orderData="orderData" @refresh="refresh" :editOrder="editOrder"/>
        <!-- <modal-order-filter @filteredOrders="filteredOrders" v-hotkey="{'f': showFilterModal}"></modal-order-filter> -->
        <mail-modal></mail-modal>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
        <modal-order-complaint @refresh="refresh" :editOrder="editOrder"></modal-order-complaint>
        <modal-order-history :orderId="orderId"></modal-order-history>
        <modal-send-message :clientPhone="clientPhone"></modal-send-message>
        <modal-for-driver-message  :perfomerPhone="perfomerPhone"></modal-for-driver-message>
        <modal-order-show :orderData="orderData" @refresh="refresh" :editOrder="editOrder"></modal-order-show>
</div>
</template>
 
<script>
import ModalForDriverMessage from "@/views/component/Modal/ModalForDriverMessage/MessageForDriver.vue"
import ModalOrderShow from '@/views/component/Modal/ModalOrder/ModalOrderShow.vue'
import ModalOrderCreate from "@/views/component/Modal//ModalOrder/ModalOrderCreate.vue"
import ModalOrderHandle from "@/views/component/Modal//ModalOrder/ModalOrderHandle.vue"
import ModalOrderEdit from "@/views/component/Modal//ModalOrder/ModalOrderEdit.vue"
import ModalOrderComplaint from "@/views/component/Modal/ModalOrder/ModalOrderСomplaint.vue"
import ModalOrderHistory from "@/views/component/Modal/ModalOrder/ModalOrderHistory.vue"
// import ModalOrderFilter from "@/views/component/Modal/ModalOrder/ModalOrderFilter.vue"
import MailModal from "@/views/component/Modal/ModalOrder/MailModal.vue"
import ModalSendMessage from "@/views/component/Modal/ModalMessage/ModalSendMessage.vue"
import VueContext from 'vue-context'
import { mask } from "vue-the-mask"
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from '@/utils/TableFunctions/cellSelect'

export default {
    directives: { mask },
    components:{
        ModalOrderShow,
        ModalForDriverMessage,
        ModalSendMessage,
        ModalOrderCreate,
       ModalOrderHandle,
       ModalOrderComplaint,
       ModalOrderEdit,
       ModalOrderHistory,
    //    ModalOrderFilter,
       VueContext,
       MailModal,
   },
    data(){
        return{
            data: [],
            clientPhone: null,
            perfomerPhone: null,
            orderData: '',
            positionId: JSON.parse(localStorage.getItem("userData")).role_id,
            memos_fields:[
                {
                    key: 'checkbox', label: '', thStyle: {width: '30px'}
                },
                {
                    key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}
                },
                {
                    key: 'phone', label: 'Телефон', sortable: true, thStyle: {width: '40px'}
                },
                {
                    key: 'comment', label: 'Комментарий', sortable: true, thStyle: {width: '40px'}
                },
                { key: 'memo_subject.name', label: 'Тема записки', sortable: true, thStyle: {width: '150px'} },
                { key: 'memo_type.name', label: 'Тип записки', sortable: true, thStyle: {width: '150px'} },
            ],
            fields: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'} },
                { key: 'id', label: 'Id' },
                { key: 'division', label: 'Подразделения' },
                { key: 'phone', label: 'Номер телефона' },
                { key: 'from_address.name', label: 'Откуда' },
                { key: 'to_addresses', label: 'Куда' },
                { key: 'last_name', label: 'Ф.И.О' },
                { key: 'performer_login', label: 'Логин' },
                { key: 'price', label: 'Цена' },
                { key: 'car_info', label: 'Автомобиль' },
                { key: 'status', label: 'Статус' },
                { key: 'type', label: 'Тип' },
                { key: 'tariff', label: 'Тариф' },
                { key: 'created_by', label: 'Создал' },
                { key: 'info_for_operator', label: 'Доп. инфо для оператора' },
                { key: 'info_for_driver', label: 'Доп. и. в.' },
                { key: 'client_status', label: 'Сатус клиента' },
                { key: 'perfomer_phone', label: 'Номер водителя' },
                { key: 'assignment_by', label: 'Назначил' },
                { key: 'dop_phone', label: 'Доп. номер' },
                { key: 'date_time', label: 'Время поступления' },
                {key: 'commission', label: 'Комиссия'}
            ],
            field: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'} },
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'} },
                { key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_login', label: 'Логин водителя', sortable: true, thStyle: {width: '150px'} },
                { key: 'from_address', label: 'Откуда', sortable: true, thStyle: {width: '220px'} },
                { key: 'to_addresses', label: 'Куда', sortable: true, thStyle: {width: '220px'} },
                { key: 'price', label: 'Цена', sortable: true, thStyle: {width: '150px'} },
                { key: 'bonus_price', label: 'Безнал', sortable: true, thStyle: {width: '150px'} },
                { key: 'phone', label: 'Номер телефона', sortable: true, thStyle: {width: '150px'} },
                { key: 'dop_phone', label: 'Доп. номер', sortable: true, thStyle: {width: '150px'} },
                { key: 'report', label: 'Запросы', sortable: true, thStyle: {width: '150px'} },
                { key: 'past_minute', label: 'ВП', sortable: true, thStyle: {width: '40px'} },
                { key: 'tariff', label: 'Тариф', sortable: true, thStyle: {width: '150px'} },
                { key: 'status', label: 'Статус', sortable: true, thStyle: {width: '150px'} },
                { key: 'created_at', label: 'Время поступления', sortable: true, thStyle: {width: '150px'} },
                { key: 'date_time', label: 'На время', sortable: true, thStyle: {width: '150px'} },
                { key: 'assignmentBy', label: 'Назначил', sortable: true, thStyle: {width: '150px'} },
                { key: 'info_for_drivers', label: 'Доп. инфо для водителя', sortable: true, thStyle: {width: '200px'} },
                { key: 'info_for_operator', label: 'Доп. инфо для оператора', sortable: true, thStyle: {width: '200px'} },
                { key: 'performer_name', label: 'Водитель', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_transport', label: 'Автомобиль', sortable: true, thStyle: {width: '150px'} },
                { key: 'create_user.login', label: 'Создал', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_phone', label: 'Номер водителя', sortable: true, thStyle: {width: '150px'} },
                { key: 'client_status', label: 'Статус клиента', sortable: true, thStyle: {width: '150px'} },
                { key: 'reason_cancel_order', label: 'Причина отмены', sortable: true, thStyle: {width: '150px'} },
                { key: 'type', label: 'Тип', sortable: true, thStyle: {width: '150px'} },
                { key: 'allowances', label: 'Надбавки', sortable: true, thStyle: {width: '150px'} },
                { key: 'distance', label: 'Расстояние', sortable: true, thStyle: {width: '150px'} },
                { key: 'history', label: 'История изминения', sortable: true, thStyle: {width: '150px'} },
                { key: 'history_call', label: 'История звонков', sortable: true, thStyle: {width: '150px'} },
                { key: 'commission_price', label: 'Комиссия', sortable: true, thStyle: {width: '150px'} },
            ],
        items: [],  
           orders: [],
           showPreloader: false,
           search_Phone: '',
           geo_location: null,
           latest_order: [],
           all_search_orders: [],
           editOrder: {
               division_id: '1',
                phone: "",
                dop_phone: undefined,
                auto_assignment: 1,
                not_issued: 0,
                for_time: 0,
                date_time: undefined,
                search_address_id: null,
                to_addresses:[
                    {
                        search_address_id: undefined    
                    }
                ],
                meeting_info: undefined,
                info_for_operator: "",
                info_for_drivers: '',
                order_type_id : '1',
                tariff_id : '1',
                price: 0,
                distance: 0.0,
                number_of_passengers: undefined,
                allowances: []
           },
           orderId: '',
           memos: [],
           refreshTimer: null,
       }
   },
   methods:{
    getOrderByPhone(){
        this.$http
        .get(`orders/order-by-phone?phone=${this.search_Phone}`)
        .then(res => { 
            this.latest_order=res.data.latest_order
            this.all_search_orders=res.data.orders
            this.memos=res.data.memos
        })
    },
    getOrderData() {
        this.$http
            .get('orders/data')
            .then(res => {
                this.orderData = res.data;
            })
    },
    getPathData(data){
        this.$store.commit('draggableTab/ADD_TAB',['История ' + data.item.id, `/order/order-history`, data.item.id  ])
        this.$router.push({name: 'orderHistory', query:{filter: data.item.id}})
    },
    getHistoryCall(data){
        this.$router.push({name: 'orderHistoryCall', query:{filter: data.item.id}})
        this.$store.commit('draggableTab/ADD_TAB',['Звонки ' + data.item.id, `/order/history-call`, data.item.id  ])
    },
     city(e, city) {
        var button = document.getElementsByClassName('but'),
        tabContent = document.getElementsByClassName('tab-content');
        var i;
        for (i = 0; i < button.length; i++) {
            tabContent[i].style.display = 'none';
            button[i].classList.remove('btn-primary');
        }
        let cityBlock=document.getElementById(city)
        cityBlock.style.display = 'block';
        e.currentTarget.classList.add('btn-primary');
    },
        sendToParent(tableData){
            this.orders = tableData
            this.$store.commit('pageData/setdataCount', this.orders.length)
            this.$store.commit('excel/SET_ORDERS', this.orders)
            this.$store.commit('excel/SET_COLUMNS', this.field)
        },
       filteredOrders(orders){
           this.orders=orders
           this.$store.commit('pageData/setdataCount', this.orders.length)
       },
        getOrder(orders){
       this.orders=orders
     },
       showCreateOrder(){
           this.$bvModal.show(this.$route.name + 'CreateModal')
       },
       handleContextMenuEvent(item, index, event){
        this.geo_location = item.geo_json_array
        this.data = item
           event.preventDefault();
           this.clientPhone = item.phone
           if (item.performer) {
               this.perfomerPhone = item.performer.phone
           }
           this.$refs.menu.open(event)
           this.orderId=item.id
       },
       selectOrder(item, index, event){
           this.$bvModal.show('edit_order')
           this.$http
            .get(`orders/${item.id}/edit`)
            .then(res => { 
                this.editOrder = res.data
            })
       },
       hovering(item, index, event){
           event.target.style.backgroundColor='#B8D2FF'
       },
       unhovering(item, index, event){
           event.target.style.backgroundColor=''
       },
       showOnTheMap() {
            let data = this.data
            this.$router.push({name: 'showOnThe-map', query:{map: data.id}})
            this.$store.commit('draggableTab/ADD_TAB',[data.id, `/showOnThe-map`, data.id  ])
        },
       openHandle(){
        this.$http
            .get(`orders/${this.orderId}/edit`)
            .then(res => { 
                this.editOrder = res.data
            })
        this.$bvModal.show('handle_order')
       },
       openHistoryModal(){
        if (this.positionId == 1) {
            this.$bvModal.show('order_history')
        }else {
            this.getPath()
        }
       },
       getOrderByIds() {
        this.$http
            .get(`orders/${this.orderId}/edit`)
            .then(res => { 
                this.editOrder = res.data
            })
       },
       getOrderById() {
        this.$http
            .get(`orders/${this.orderId}/edit`)
            .then(res => { 
                this.editOrder = res.data
            })
       },
       getHistory(){
        this.showPreloader = true
        this.$http
        .get(`orders/${this.orderId}/histories`)
        .then(res => {
            this.orders = res.data
            this.$store.commit('pageData/setdataCount', this.orders.length)
            this.showPreloader = false
        })
        .catch(err =>{
            console.log(err);
        })
        },
        getLogin(data){
          this.$router.push({name: 'infoLogin', query:{filter: data.item.performer.login}})
          this.$store.commit('draggableTab/ADD_TAB',['Водитель ' + data.item.performer.login, `/infoLogin`, data.item.performer.login])
        },
        getOrder(orders){
        this.orders=orders
        this.$store.commit('pageData/setdataCount', this.orders.length)
        },
        clicked(item, index, event){
            this.data = item
            this.$store.state.filterPlus.tableIndex = index
            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.field.length) {
                return;
            }

            const clickedField = this.field[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            this.orderId = item.id
            cellSelect()
    },
        refresh(){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let access = JSON.parse(localStorage.getItem('userData'))
            if (access.role_id == 2) {
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res => {
                    this.showPreloader = true
                    setTimeout(() => {
                        this.orders = []
                        this.showPreloader = false;
                        this.orders = res.data
                        this.$store.commit('pageData/setdataCount', this.orders.length)
                    }, "200")
                    this.$store.commit('excel/SET_ORDERS', this.orders)
                    this.$store.commit('excel/SET_COLUMNS', this.field)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }else{
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res => { 
                    this.orders = []
                    this.$store.commit('excel/SET_ORDERS', this.orders)
                    this.$store.commit('excel/SET_COLUMNS', this.field)
                    this.showPreloader = true
                    setTimeout(() => {
                        this.orders = res.data
                        this.showPreloader = false;
                        this.$store.commit('pageData/setdataCount', this.orders.length)
                    }, "200")
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        },
        getPath(){
            this.$router.push({name: 'orderHistory', query:{filter: this.orderId}})
            setTimeout(() => {
                this.$store.commit('draggableTab/ADD_TAB',['История ' + this.orderId, `/order/order-history`, this.orderId  ])
            }, 200)
        },
        getAssigment(data){
            this.$store.commit('draggableTab/ADD_TAB',['Запросы ' + data.item.id, `/order/assigment`, data.item.id  ])
            this.$router.push({name: 'report/assigment', query:{filter: data.item.id}})
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.orders = []
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.orders = res.data
                        this.$store.commit('pageData/setdataCount', this.orders.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                        this.$store.commit('excel/SET_ORDERS', this.orders)
                        this.$store.commit('excel/SET_COLUMNS', this.field)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
    rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.status_id === 10) return 'table-danger'
        else if (item.status_id === 11) return 'table-warning'
        else if (item.status_id === 9) return 'table-success'
        else if (item.status_id === 1) return ''
        else return 'table-secondary'
      },
//       resizingBlocks(){
//         {
//     "use strict";
//     // vertical direction
//     (function resizableY() {
//       const resizer = document.querySelector(".resizer_btn");
//       resizer.addEventListener("mousedown", onmousedown);
//       resizer.addEventListener("touchstart", ontouchstart);
  
//       // for mobile
//       function ontouchstart(e) {
//         e.preventDefault();
//         resizer.addEventListener("touchmove", ontouchmove);
//         resizer.addEventListener("touchend", ontouchend);
//       }
//       function ontouchmove(e) {
//         e.preventDefault();
//         const clientY = e.touches[0].clientY;
//         const deltaY = clientY - (resizer._clientY || clientY);
//         resizer._clientY = clientY;
//         const t = resizer.previousElementSibling;
//         const b = resizer.nextElementSibling;
//         // UP
//         if (deltaY < 0) {
//           const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
//           t.style.flex = `0 ${h < 10 ? 0 : h}px`;
//           b.style.flex = "1 0";
//         }
//         // DOWN
//         if (deltaY > 0) {
//           const h = Math.round(parseInt(getComputedStyle(b).height) - deltaY);
//           b.style.flex = `0 ${h < 10 ? 0 : h}px`;
//           t.style.flex = "1 0";
//         }
//       }
//       function ontouchend(e) {
//         e.preventDefault();
//         resizer.removeEventListener("touchmove", ontouchmove);
//         resizer.removeEventListener("touchend", ontouchend);
//         delete e._clientY;
//       }
  
//       // for desktop
//       function onmousedown(e) {
//         e.preventDefault();
//         document.addEventListener("mousemove", onmousemove);
//         document.addEventListener("mouseup", onmouseup);
//       }
//       function onmousemove(e) {
//         e.preventDefault();
//         const clientY = e.clientY;
//         const deltaY = clientY - (resizer._clientY || clientY);
//         resizer._clientY = clientY;
//         const t = resizer.previousElementSibling;
//         const b = resizer.nextElementSibling;
//         // UP
//         if (deltaY < 0) {
//           const h = Math.round(parseInt(getComputedStyle(t).height) + deltaY);
//           t.style.flex = `0 ${h < 10 ? 0 : h}px`;
//           b.style.flex = "1 0";
//         }
//         // DOWN
//         if (deltaY > 0) {
//           const h = Math.round(parseInt(getComputedStyle(b).height) - deltaY);
//           b.style.flex = `0 ${h < 10 ? 0 : h}px`;
//           t.style.flex = "1 0";
//         }
//       }
//       function onmouseup(e) {
//         e.preventDefault();
//         document.removeEventListener("mousemove", onmousemove);
//         document.removeEventListener("mouseup", onmouseup);
//         delete e._clientY;
//       }
//     })();
//   }
//       }
   },

   mounted(){
    this.$store.commit('pageData/setdataCount', null)
    this.getOrderData()
    this.openFilter()
    resizeable()
   },
   beforeDestroy() {
    clearInterval(this.refreshTimer)
    this.$store.commit('excel/SET_ORDERS', [])
    this.$store.commit('excel/SET_COLUMNS', [])
   },
   computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
   },
   watch: {
        fetchingNewData(val){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if (val){
                this.orders = []
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res =>{
                    this.orders = res.data
                    this.$store.commit('pageData/setdataCount', this.orders.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
   },
}   
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.order-card .card-body{
    padding: 0;
    margin: 0;
}
.order-card{
    width: fit-content;
}
table{
    outline: none; 
    table-layout: fixed; 
    user-select: none;
    overflow: hidden;
    position: relative
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
} 
.parent_block{
  overflow: hidden;
}
.resizer_btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 0px;
}

.resizer_btn{
  z-index: 1;
  cursor: row-resize;
  flex-direction: column;
}

.resizer_btn::after {
  content: "";
  width: 40px;
  height: 4px;
  margin: 2px;
  border-radius: 10px;
  background: rgb(143, 141, 141);
}
.block1,
.block2 {
  overflow: scroll;
}
.order-list {
    @font-face {
        font-family: "Verdana";
        src: url("../../../assets/fonts/Verdana.ttf");
    }
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
}
</style>
 
