<template>
    <b-row>
        <b-col cols="2">
            <p>{{ field.label }}</p>
        </b-col>
        <b-col class="mb-1">
            <v-select
                label="name"
                :options="street"
                v-model="streetCode"
                :reduce="option => option.code"
                @input="updateModel($event, 'streetCode')"
            />
        </b-col>
    </b-row>
</template>

<script>
import vSelect from 'vue-select'
export default {
    components: {
        vSelect
    },
    props: ['value', 'field'],
    name: 'streetCode',
    model: {
        prop: 'value',
        event: 'change'
    },
    data() {
        return {
            street: [],
            streetCode: null,
        }
    },
    mounted() {
        if (this.$store.state.filterPlus.filterPlus !== null && this.$store.state.filterPlus.keyFilter === 'cityCode' && this.$store.state.filterPlus.addFilterPlus) {
            const value = this.$store.state.filterPlus.filterPlus;
            const name = 'streetCode';
            this.updateModel(value, name);
        }
        this.getStreet()
    },
    methods: {
        getStreet() {
            this.$http.get('admin/street')
            .then((res) => {
                this.street = res.data
            })
        },
        updateModel(value, name) {
            this.$emit('change', { val: value, field: name })
        }
    }
}
</script>

<style lang="scss" scoped>

</style>